.intro {
   margin-top: 10px;
}

.container_for_header_intro {
  padding-top: 116px;
  background: #f6f6f8;
  position: relative;
  height: 640px;
  margin: 0 24px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 -1px 0 0 rgba(47, 43, 67, 0.1) inset,
    0 1px 3px 0 rgba(47, 43, 67, 0.1);
}

.intro .title {
  text-align: center;
  padding: 0 20px;
  max-width: 990px;
  margin: 24px auto 0;

  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px; /* 100% */
  letter-spacing: -2.88px;
}
.intro .description {
  padding-top: 24px;
  max-width: 454px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.18px;
  color: #000000;
}

.face-in-cube {
  position: absolute;
  top: 0;
  right: 14%;
  max-width: 214px;
  max-height: 183px;
}

.face-in-cube-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.worm-in-cube {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 464px;
  max-height: 411px;
}

.worm-in-cube-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.cursor-wrapper-img {
  position: absolute;
  bottom: 10%;
  right: 6%;
  max-width: 291px;
  max-height: 187px;
}

.cursor-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 1269px) {
  .intro .description {
    font-size: 20px;
    line-height: 30px;
    max-width: 450px;
  }
}

@media (max-width: 991px) {
  .intro .description {
    font-size: 18px;
    line-height: 26px;
    max-width: 400px;
  }
  .intro .title {
    font-size: 65px;
    line-height: 98%;
  }
}

@media (max-width: 767px) {
  .intro {
    padding-top: 70px;
  }
  .intro .title {
    padding: 0;
    font-size: 59px;
    line-height: 98%;
  }
}

@media (max-width: 575px) {
  .intro .title {
    font-size: 58px;
    line-height: 98%;
  }
}

@media (max-width: 550px) {
  .intro .title {
    font-size: 55px;
    line-height: 98%;
  }
}

@media (max-width: 500px) {
  .intro .title {
    font-size: 50px;
    line-height: 98%;
  }
}

@media (max-width: 450px) {
  .intro .title {
    font-size: 45px;
    line-height: 98%;
  }
}

@media (max-width: 400px) {
  .intro .title {
    font-size: 40px;
    line-height: 98%;
  }
}

@media (max-width: 350px) {
  .intro .title {
    font-size: 43px;
    line-height: 98%;
  }
  .worm-in-cube {
    display: none;
  }

  .face-in-cube {
    display: none;
  }
}



@media (max-width: 768px) {
  .cursor-wrapper-img {
    display: none;
  }

  .worm-in-cube {
    max-width: 180px;
    max-height: 220px;
  }
}

@media (max-width: 430px) {
  .worm-in-cube {
    bottom: -38px;
  }
  .face-in-cube {
    top: -24px;
  }
}

@media (max-width: 320px) {
  .container_for_header_intro {
    padding-top: 30px;
  }
  .form form {
    margin-top: 25px !important;
  }
}
