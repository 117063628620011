.error-404 .content-row {
  padding-top: 195px;
}
.error-404 .text-content {
  padding-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -.5px;
  color: #000;
}
.error-404 .button-icon {
  margin-top: 30px;
}
.error-404 .site-footer,
.error-404 .site-footer.bordered {
  margin-top: 200px;
}

@media (max-width: 991px) {
  .error-404 .site-footer,
  .error-404 .site-footer.bordered {
    margin-top: 150px;
  }
  .error-404 .content-row {
    padding-top: 140px;
  }
  .error-404 .text-content {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (max-width: 575px) {
  .error-404 .content-row img {
    max-width: 100%;
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}