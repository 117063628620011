@import "../common_styles/colors";

$SubscriptionPlanCardBorder: 1px solid $grey-light-color;

.SubscriptionPlanCard {
  border: $SubscriptionPlanCardBorder;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  color: #000c17;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 15px 20px 13px;
    border-bottom: $SubscriptionPlanCardBorder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: 15px 20px 13px;
  }

  &__footer {
    padding: 13px 20px 15px;
    margin-top: auto;
    display: flex;
    align-items: flex-end;

    .btn {
      width: 100%;
      border-width: 1px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.04em;
    margin-right: 15px;
  }

  &__price-for-one {
    margin-top: 20px;

    width: max-content;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #000000;

    background: rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    svg {
      margin-top: -3px;
    }
  }

  &__price {
    display: flex;
    align-items: flex-end;

    p:first-child {
      font-weight: 600;
      font-size: 70px;
      line-height: 100%;
      letter-spacing: -3px;
    }

    p:last-child {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__oldprice {
    display: flex;
    align-items: flex-end;
    color: #000;
    color: rgba(0, 0, 0, 0.3);
    margin-top: 5px;

    p:first-child {
      text-decoration: line-through;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }

    p:last-child {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__downloads {
    font-size: 18px;
    line-height: 26px;
    color: #000;
    color: rgba(0, 0, 0, 0.6);
  }

  &_active {
    @extend .SubscriptionPlanCard;

    border-color: #000;
  }

  &_big {
    @extend .SubscriptionPlanCard;

    .SubscriptionPlanCard__header {
      border-bottom: none;
    }

    .SubscriptionPlanCard__footer .btn {
      font-weight: 500;
    }

    .SubscriptionPlanCard__description {
      padding-top: 16px;
      padding-right: 20px;

      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 145%;

      color: #7C7C7C;
    }

    .SubscriptionPlanCard__downloads p:first-child {
      font-weight: 600;
      font-size: 90px;
      line-height: 100%;

      letter-spacing: -3px;
    }

    .SubscriptionPlanCard__downloads p:last-child {
      font-size: 18px;
      line-height: 120%;
      overflow: hidden;
    }

    .SubscriptionPlanCard__price {
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
    }

    .SubscriptionPlanCard__body {
      padding: 0 20px 13px;
    }

    .SubscriptionPlanCard__footer {
      padding: 13px 30px 30px;
    }

    .SubscriptionPlanCard__header {
      padding: 30px;
      padding-bottom: 15px;
    }

    .SubscriptionPlanCard__body {
      padding: 0 30px 13px;
    }
  }

  &_yellow {
    @extend .SubscriptionPlanCard;
    background-color: #FFDF57;
    border-color: transparent;

    .SubscriptionPlanCard__description {
      color: #000;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &_black{
    @extend .SubscriptionPlanCard;
    background-color: #000000;
    border-color: transparent;

    .SubscriptionPlanCard__name {
      color: #ffffff;
    }

    .SubscriptionPlanCard__price {
      color: #ffffff;
    }

    .SubscriptionPlanCard__downloads p:first-child,
    .SubscriptionPlanCard__downloads p:last-child {
      color: #ffffff;
    }
  }
}



@media screen and (max-width: 1270px) {
  .SubscriptionPlanCard {

    &_big {
      .SubscriptionPlanCard__footer {
        padding: 13px 25px 25px;
      }

      .SubscriptionPlanCard__header {
        padding: 15px 25px 13px;
      }

      .SubscriptionPlanCard__body {
        padding: 0 25px 13px;
      }
    }
  }
}




