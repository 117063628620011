.browse-by-packs .content-row {
  padding-top: 30px;
}
.browse-by-packs .button-base {
  margin-top: 40px;
}
.browse-by-packs .body {
  margin-top: 30px;
}
.browse-by-packs .footer {
  margin-top: 40px;
}