.browse-by-categories {
  .content-row {
    display: flex;
    padding-top: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .item {
    margin-bottom: 30px;
    padding: 0 10px;
  }

  .browse-by-card {
    padding: 0;
    overflow: hidden;

    .description {
      position: absolute;
      bottom: 22px;
      padding: 0;
    }

    &_illustrations {
      background: #FFF9E6;

      .description {
        color: #000000;
      }
    }

    &_3d {
      background: #EBF1FE;

      .description {
        color: #000000;
      }
    }

    &_elements {
      background: rgba(61, 152, 231, 1);
      padding-top: 36px;

      img {
        width: 88px;
        height: 88.5px;
        margin-right: 6px;
      }

      .description {
        color: #ffffff;
      }
    }

    &_web {
      background: rgba(82, 82, 82, 1);
      padding-top: 8px;

      img {
        width: 222px;
        height: 177px;
      }

      .description {
        color: #ffffff;
      }
    }

    &_application {
      background: rgba(188, 235, 56, 1);

      .description {
        color: #000000;
      }
    }

    &_emails {
      background: rgba(172, 99, 244, 1);

      .description {
        color: #ffffff;
      }
    }

    &_mockups {
      background: #FFEEF5;

      .description {
        color: #000000;
      }
    }
  }

  .browse-by-card:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.16);
  }
}

@media (min-width: 480px) {
  .browse-by-categories .content-row {
    margin: 0px 60px;
  }
}

@media (min-width: 767px) {
  .browse-by-categories .content-row {
    margin: 0px 110px;
  }
}
