.pack .button-icon.sticky {
    top: 20px;
    margin-left: 15px;
}
.pack .escape {
    margin-top: 20px;
}
.pack .illustrations {
    padding-top: 80px;
}
.pack .illustrations-list {
    padding-top: 35px;
}
.pack .before-footer {
    margin-top: 70px;
}

@media (max-width: 575px) {
    .pack .illustrations-pack-full {
        margin-top: 30px;
    }
}
