.ContactUs {
  margin-top: 67px;
  margin-bottom: 135px;
}

.ContactUs picture {
  display: block;
  max-width: 90%;
  margin: 0 auto 0 auto;
}

.ContactUs picture img {
  width: 85%;
}

.ContactUs__header {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ContactUs__maintext {
  max-width: calc(90%);
  margin-bottom: 88px;
}

.ContactUs__container_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ContactUs__container_flex .ContactUs__header {
  margin-bottom: 15px;
}

.ContactUs__container_flex a,
.ContactUs__maintext a {
  color: #7C7C7C;
}

.ContactUs__container_flex a:hover,
.ContactUs__maintext a:hover {
  color: #000;
}

.ContactUs__container-item:first-child {
  margin-right: 30px;
}

.ContactUs__links {
  display: flex;
  flex-wrap: wrap;
}

.ContactUs__links a {
  margin-right: 30px;
}

.ContactUs__links a:last-child {
  margin-right: 0;
}

@media (max-width: 991px) {
  .ContactUs__firstcolumn {
    margin-bottom: 40px;
  }

  .ContactUs__header {
    font-size: 38px;
    line-height: 44px;
    letter-spacing: -1.8px;
    margin-bottom: 18px;
  }

  .ContactUs__maintext {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .ContactUs__header {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .ContactUs__container_flex {
    flex-direction: column;
  }

  .ContactUs__container-item:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
