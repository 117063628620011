.packs-grid .MuiSkeleton-root {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0.01) 0%,
                  rgba(0, 0, 0, 0.03) 100%
  );
}

.packs-grid .MuiSkeleton-rect,
.packs-grid .MuiSkeleton-text {
  border-radius: 15px;
}

.packs-grid {
  &__title {
    color: var(--Black, #000);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.92px;
  }

  &__description {
    color: #2f2b4399;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.18px;
    line-height: 26px;
    top: 0;
    max-width: 588px;
    margin: 24px auto 32px;
    text-align: center;
  }

  &__grid-smart {
    margin-top: 64px;
    margin-bottom: 128px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 60px 30px;
  }

  &__grid {
    margin-top: 64px;
    margin-bottom: 128px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 64px 30px;
  }

  &__button {
    margin: 0 auto 0;
    width: max-content;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    justify-items: center;
    border-radius: 12px;
    border: 1px solid rgba(47, 43, 67, 0.1);
    background: #fff;
    -webkit-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    transition: all 0.15s linear;
    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.1) inset,
    0px 1px 3px 0px rgba(47, 43, 67, 0.1);
  }

  &__button_packs {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__button:hover {
    border: 1px solid rgba(47, 43, 67, 0.20);
    background: #F6F6F8;

    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10);
    z-index: 90;
    background-clip: content-box;
  }

  &__button:active {
    border: 1px solid rgba(47, 43, 67, 0.20);
    background: var(--Primary-100, #EDEDF1);
  }

  &__button_content {
    color: var(--Black, #000);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  &__skeleton-box {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &__skeleton-container {
    flex-grow: 1;
  }

  &__skeleton-circle {
    margin-right: 14px;
  }

  &__skeleton-text {
    margin-top: 10px;
  }

  &__skeleton-text:first-of-type {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .packs-grid {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: 1270px) {
  .packs-grid {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (min-width: 1600px) {
  .packs-grid {
    padding: 0 !important;
  }
}
