.newsletter_card {
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    box-shadow: 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset, 0 1px 3px 0 rgba(47, 43, 67, 0.10);
    padding: 28px;
    width: 48%;
    min-width: 0;
}


.btn-pink {
    border-radius: 12px !important;
    background: #FECFFF !important;
    /* Shadows/1 */
    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10) !important;
    color: #2C3FEE !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important; /* 150% */
}

.btn-pink:hover {
    background: #F7C1F9 !important;
}

.btn-pink:active {
    background: #F2B6F3 !important;
}


.form-elements {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-shrink: 0;
    /*justify-content: space-between;*/
}


.newsletter__input_wrapper {
    width: 100%;
    max-width: 280px;
    align-items: flex-end;
    margin: 0 !important;
}

.ant-form-item-explain .ant-form-item-explain-error {
    display: none !important;
}

.ant-row .ant-form-item {
    align-items: flex-end !important;
    margin: 0 !important;
}


.newsletter__input {
    display: flex;
    border-radius: 12px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    padding: 12px 16px;
    box-shadow: 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset, 0 1px 3px 0 rgba(47, 43, 67, 0.10);
    max-width: 280px;
    width: 100%;
}


.newsletter_card .first_block {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
}




.newsletter__button {
    width: 30%;
    max-width: 108px;
}




.block-title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.64px;
}

.icon {
    width: 144px;
    height: 144px;
    flex-shrink: 0;
}


@media (min-width: 1600px) {
    .icon {
        width: 184px;
        height: 184px;
        flex-shrink: 0;
    }
}


@media (max-width: 465px) {
    .block-title {
        font-size: 22px;
        line-height: 30px;
    }

    .form-elements {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .newsletter_card {
        width: 100%;
    }
}

@media (max-width: 1278px) {
    .icon {
        width: 100px;
        height: 100px;
    }

    .newsletter_card {
        justify-content: center;
    }
}

@media (max-width: 992px) {
    .newsletter_card {
        width: 100%;
    }
}

@media (max-width: 498px) {
    .first_block {
        align-items: center;
        text-align: center;
    }
    .newsletter_card {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .form-elements {
        flex-direction: column;
        align-items: center;
    }

    .newsletter__input_wrapper {
        margin-bottom: 0;
    }

    .newsletter__button {
        width: 100% !important;
        flex-shrink: 0;
    }

    .btn-pink {
        width: 100% !important;
        max-width: 100%;
    }
}