$grey-main-color: #a9a9a9;
$blue-main-color: #2C3FEE;
$grey-light-color: #e7e7e7;
$grey-dark-color: #7c7c7c;
$grey-border-color: #f2f2f2;

//Buttons colors
$primary-color: #2c3fee;
$accent-color: #b5f2fa;
$light-blue-color: #b5f2fa;
$pink-color: #fecfff;
$blue-color: $primary-color;
$secondary-color: #9130de;
$violet-color: $secondary-color;
$white-color: #ffffff;
$third-color: #25ea5c;
$green-color: $third-color;
$red-color: #fe3434;
$danger-color: $red-color;
