.how-it-works {
    margin-top: 128px;
}

.how-it-works .title {
    color: #000;
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.92px;
    max-width: 636px;
    margin: 0 auto;
}

.cu-cards-container {
    max-width: 984px;
    margin: 64px auto 0;
    gap: 30px;
    display: flex;
}

.abstract-card {
    border-radius: 24px;
    overflow: hidden;
    padding: 28px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    width: 35%;
    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.abstract-card .field {
    border-radius: 20px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    padding: 21px 28px;
    color: rgba(47, 43, 67, 0.60);

    font-family: "Euclid Circular A";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 150% */
    letter-spacing: -0.28px;


    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.abstract-card .search {
    margin-top: 17px;
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px 0;
    width: 100%;

    color: #1F54C8;
    font-family: "Euclid Circular A";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px; /* 150% */
    letter-spacing: -0.28px;
    background: #B5F2FA;
    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.abstract-card .search .pointer {
    position: absolute;
    right: 0;
    bottom: -40px;
}

.abstract-card-text-container {
    margin-top: 67px;
}

.abstract-card-text-container .title {
    color: #000;
    font-family: "Euclid Circular A";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.192px;
}

.abstract-card-text-container .text {
    margin-top: 8px;
    color: rgba(47, 43, 67, 0.60);
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.graphics-card {
    border-radius: 24px;
    position: relative;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    overflow: hidden;
    width: 65%;
    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.abstract-bg {
    object-fit: cover;
    max-width: 600px;
    max-height: 420px;
    position: absolute;
}

.graphics-card-text-container {
    position: absolute;
    bottom: 28px;
    left: 28px;
    max-width: 268px;
}


.graphics-card-text-container .title {
    color: #000;
    font-family: "Euclid Circular A";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.192px;
}

.graphics-card-text-container .text {
    margin-top: 8px;
    color: rgba(47, 43, 67, 0.60);
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}


.magic-card {
    margin: 30px auto 0;
    padding-left: 28px;
    padding-top: 28px;
    overflow: hidden;
    max-height: 420px;
    height: 100%;
    max-width: 984px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 24px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.magic-card .first-text span {
    color: #000;
    font-family: "Euclid Circular A";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.192px;
}

.magic-card .first-text p {
    color: rgba(47, 43, 67, 0.60);
    max-width: 268px;
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}


.magic-card .second-text {
}

.magic-card .second-text p {
    color: #53B483;
    margin-top: 12px;
    margin-bottom: 28px;
    max-width: 268px;
    font-family: "Euclid Circular A";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.magic-card .second-text .title-wrapper svg {
    position: absolute;
    right: -17px;
}


.magic-card .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 268px;
    width: 100%;
}

.magic-card .second-text .xtitle {
    position: relative;
    color: #2F7657;
    font-family: "Euclid Circular A";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.image-block {
    display: flex;
    margin-left: 188px;
    flex-direction: column;
    padding-bottom: 1px;
    gap: 0;
}

.image-block .main-image .blank_preview {
    max-width: 396px;
    object-fit: contain;
}

.image-block .main-image {
    position: relative;
}

.image-block .main-image-2 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(47, 43, 67, 0.10);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    max-width: 396px;
    /* Shadows/1 */
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.image-block .main-image .drake-meme {
    position: absolute;
    left: -90px;
    width: 120px;
    top: 160px;
}

.image-block .main-image .nice-man {
    width: 160px;
    position: absolute;
    right: -100px;
    top: 220px;
}



.dribble-link {
    color: #53B483;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-decoration: underline !important;
}

.dribble-link:hover {
    color: #53B483;
}
.dribble-link:active {
    color: #53B483;
}

@media (max-width: 768px) {
    .cu-cards-container .abstract-card {
        width: 50%;
        gap: 12px;
    }
    .image-block {
        margin-left: 60px;
    }
    .nice-man {
        display: none;
    }
    .drake-meme {
        display: none;
    }
}

@media (max-width: 768px) {
    .cu-cards-container {
        flex-direction: column;
    }
    .cu-cards-container .abstract-card {
        width: 100% !important;
    }
    .cu-cards-container .graphics-card {
        width: 100% !important;
        height: 100% !important;
        min-height: 420px;
    }

    .magic-card {
        padding: 28px;
    }
    .graphics-card-text-container {
        padding-right: 28px;
    }
    .magic-card .image-block {
        display: none;
    }
    .magic-card .text-container .second-text {
        margin-top: 50px;
    }
}