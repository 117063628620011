@import '../common_styles/colors';

.Checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .text-link {
      margin-bottom: 30px;
    }
  }

  &__order {
    margin-bottom: 30px;
  }

  &__details {
    margin-bottom: 30px;
  }

  &__table {
    .ant {
      &-table {
        &-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          display: none;
        }

        &-cell {
          width: 50%;
        }
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark-color;
    overflow: hidden;
  }

  .SummaryCard {
    margin-top: 50px;
  }

  .btn {
    width: 100%;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }

  a {
    color: #000;
  }

  &__warning {
    border-radius: 10px;
    border: 1px solid $grey-light-color;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    &-icon {
      background: rgba(123, 198, 110, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }

    p {
      color: #000;
    }
  }
} 
