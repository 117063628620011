.black-friday-popup__all_opaque {
  opacity: 1;
}

.black-friday-popup__all_transparent {
  opacity: 0;
}

.black-friday-popup__all {
  position: relative;
  z-index: 101;
  transition: opacity 0.2s ease-in-out;
}

.black-friday-popup__shade.fade.modal-backdrop.custom-backdrop {
  display: none;
}

.black-friday-popup {
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 102;
  width: 560px;
  background-color: #000000;
  border-radius: 50px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  padding: 50px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  overflow: hidden;

  &__close {
    display: inline-block;
    text-align: right;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    z-index: 15;
  }

  &__close svg {
    transition: transform 0.2s ease-in-out;
  }

  &__close:hover svg {
    cursor: pointer;
    transform: rotate(90deg);
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__image {
    position: relative;
    width: 484px;
    height: 363px;
  }

  &__image-wrapper {
    position: relative;
  }

  &__text {
    position: relative;
    color: #ffffff;
    font-size: 24px;
    line-height: 34px;
    font-weight: normal;
    margin-bottom: 40px;
  }

  &__button {
    padding: 15px 40px;
    font-size: 22px;
    line-height: 34px;
    font-weight: normal;
    letter-spacing: -0.3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #B5F2FA;
    background: rgba(145, 48, 222, 1);
    border-radius: 10px;

    &:hover {
      color: #B5F2FA;
      background-color: rgba(145, 48, 222, 0.7);
    }
  }
}

@media (max-width: 1023px) {
  .black-friday-popup__shade.fade.modal-backdrop.custom-backdrop {
    display: block;
    visibility: visible;
    opacity: 0.4;
    animation-duration: .2s;
    transition: all .2s;
    z-index: 101;
  }

  .black-friday-popup {
    z-index: 102;
    left: 50%;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 600px) {
  .black-friday-popup {
    width: 93%;
    padding: 30px;

    &__image {
      width: 100%;
      height: auto;
    }

    &__image-wrapper {

      &:before {
        content: '';
        position: absolute;
        width: 90%;
        height: 45%;
        z-index: 10;
      }
    }
  }
}
