.filter-dropdown {
  &__container {
    background-color: #fff;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 5px;
    display: flex;
    flex-flow: column nowrap;
    min-width: 150px;

    .filter-menu {
      display: flex;
      flex-direction: column;
    }

    .filter-menu__option {
      width: 100%;
    }
  }

  &__button {
    min-width: 150px;
    box-sizing: border-box;

    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 8px 15px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;

    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__divider {
    margin: 3px 0;
    border-color: #E7E7E7;
  }

  &__checkbox-group {
    display: flex;
    flex-direction: column;
  }

  &__checkbox {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 6px 10px 8px;
    color: #000000;

    .ant-checkbox + span {
      padding-left: 9px;
      padding-right: 0;
    }

    .ant-checkbox-checked::after {
      border: none;

      border-radius: 5px;
    }

    .ant-checkbox .ant-checkbox-inner {
      background: #E7E7E7;
      border: none;

      border-radius: 5px;
      width: 18px;
      height: 18px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: #000000;
      border: none;

      border-radius: 5px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 25%;
      border-radius: 1px;
    }

  }

  &__checkbox:hover .ant-checkbox-inner {
    border: none;
  }

  &__checkbox + &__checkbox {
    margin-left: 0;
  }

  &__switch {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 6px 0 12px;
    margin-bottom: 10px;

    &-label {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      cursor: pointer;
    }

    &-button {
      background-color: #E7E7E7;
      min-width: 42px;
      height: 24px;
      box-shadow: none;
      --antd-wave-shadow-color: #E7E7E7;

      .ant-switch-handle {
        top: 3px;
        left: 3px;
      }

      .ant-switch-handle::before {
        box-shadow: none;
      }
    }

    &-button:focus {
      box-shadow: none;
    }

    .ant-switch-checked {
      background-color: #000000;
      box-shadow: none;

      .ant-switch-handle {
        left: calc(100% - 18px - 3px);
      }
    }

    .ant-switch-checked:focus {
      box-shadow: none;
    }
  }

  &__switch:last-of-type {
    margin-bottom: 0;
  }
}
