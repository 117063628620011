.rating-card {
  margin: 35px auto 0;
  padding: 0 15px;

  &__container {
    display: block;
    margin: 0 auto;
    padding: 22px 25px 19px;
    background-color: #F1F6F4;
    width: 100%;
    max-width: 350px;
    border-radius: 15px;
    text-align: center;
  }

  &__star-box {
    background: url("https://storytale-public2.b-cdn.net/static/star.svg") repeat-x top left;
    background-size: 24px 24px;
    width: 120px;
    height: 24px;
    margin: 0 auto;
  }

  &__star-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #2F4D70;
  }

  &__star-text {
    margin-top: 19px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2F4D70;
  }

  &__logo {
    display: block;
    margin: 15px auto 0;
    width: 50%;
  }
}
