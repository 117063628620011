@import "colors";

@mixin buttonColors($color, $hoverAmount, $activeAmount) {
  color: #fff;
  background-color: $color;
  border-color: $color;

  &:focus {
    color: #fff;
    background-color: $color;
    border-color: $color;
  }

  &:hover{
    color: #fff;
    background-color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:active {
    color: $grey-light-color;
    background-color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }

  &.disabled,
  &:disabled,
  &.disabled:hover,
  &:disabled:hover {
    color: #fff;
    background-color: $grey-main-color;
    border-color: $grey-main-color;
    cursor: not-allowed;
  }
}

@mixin outlineButtonColors($color, $hoverAmount, $activeAmount) {
  background-color: #fff;
  color: $color;
  border-color: $color;

  &:not(:disabled):not(.disabled).hover,
  &:not(:disabled):not(.disabled):hover {
    background-color: #fff;
    color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:active {
    background-color: #fff;
    color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }

  &.disabled,
  &:disabled {
    color: $grey-main-color;
    background-color: #fff;
    border-color: $grey-main-color;
    cursor: not-allowed;
  }
}

@mixin pastel($textColor, $backgroundColor) {
  @include buttonColors($backgroundColor, 5, 5);
  color: $textColor;
  &:focus {
    color: $textColor;
  }

  &:hover {
    color: lighten($textColor, 12);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:active {
    color: darken($textColor, 12);
  }
}

.btn{
  font-weight: 600;
  border-radius: 10px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.5px;
  padding: 12px 15px;
  border-width: 2px;
  text-shadow: none;
  box-shadow: none;
  height: auto;

  &__icon {
    vertical-align: baseline;

    &_right {
      @extend .btn__icon;

      margin-right: 14px;
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }

  &-primary {
    @extend .btn;

    @include buttonColors($primary-color, 20, 20);
  }

  &-secondary {
    @extend .btn;

    @include buttonColors($secondary-color, 20, 20);
  }

  &-white {
    @extend .btn;

    @include buttonColors($white-color, 20, 20);
    color: #000;

    &:hover {
      color: lighten(#000, 20);
      background-color: lighten(#fff, 10);

      svg {
        path {
          fill: lighten(#000, 20);
        }
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      color: lighten(#000, 10);
      background-color: darken(#fff, 10);

      svg {
        path {
          fill: lighten(#000, 20);
        }
      }
    }
  }

  &-third {
    @extend .btn;

    @include buttonColors($third-color, 20, 20);
    color: #000;

    svg {
      path {
        fill: #000;
      }
    }

    &:hover {
      color: lighten(#000, 20);

      svg {
        path {
          fill: lighten(#000, 20);
        }
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      color: darken(#000, 20);

      svg {
        path {
          fill: #000;
        }
      }
    }
  }

  &-danger {
    @extend .btn;

    @include buttonColors($danger-color, 20, 20);
  }

  &-black {
    @extend .btn;

    @include buttonColors(#000, 20, 20);
  }

  &-orange {
    @extend .btn;

    @include buttonColors(#de6430, 20, 20);
  }

  &-gray {
    @extend .btn;

    background-color: #f2f2f2;
    color: #000;
    border-color: #f2f2f2;

    &:hover{
      background-color: lighten(#f2f2f2, 5);
      color: lighten(#000, 40);
      border-color: lighten(#f2f2f2, 5);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      background-color: darken(#f2f2f2, 20);
      color: #000;
      border-color: darken(#f2f2f2, 20);
    }
  }

  &-light-blue {
    @extend .btn;
    @include pastel($blue-main-color, $light-blue-color);
    border-width: 0;
    border-radius: 10px;

    padding: 12px 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    min-width: 125px;
  }

  &-light-blue_big {
    @extend .btn;
    @include pastel($blue-main-color, $light-blue-color);
    border-width: 0;
    border-radius: 15px;

    padding: 18px 30px;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.02em;
  }

  &-pink {
    @extend .btn;
    @include pastel($blue-main-color, $pink-color);
    border-width: 0;
    border-radius: 10px;

    padding: 12px 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    min-width: 125px;
  }


  &-outline {
    @extend .btn;

    background-color: #fff;
    color: #000;
    border-color: $grey-border-color;

    &:hover{
      background-color: #fff;
      color: lighten(#000, 40);
      border-color: $grey-light-color;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      background-color: #fff;
      color: darken(#000, 20);
      border-color: $grey-main-color;
    }

    &_light {
      @extend .btn-outline;

      font-weight: 500;
    }
  }

  &-outline.blue {
    border-color: $blue-main-color;
    color: $blue-main-color;

    &:hover{
      color: white;
      background-color: $blue-main-color;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      color: white;
      background-color: lighten($blue-main-color, 10);
      border-color: lighten($blue-main-color, 10);
    }

    &.disabled,
    &:disabled {
      color: $grey-main-color;
      background-color: #fff;
      border-color: $grey-main-color;
      cursor: not-allowed;
    }
  }

  &-outline-primary {
    @extend .btn;

    @include outlineButtonColors($primary-color, 20, 20);
  }

  &-outline-secondary {
    @extend .btn;

    @include outlineButtonColors($secondary-color, 20, 20);
  }

  &-outline-danger {
    @extend .btn;

    @include outlineButtonColors($danger-color, 20, 20);
  }

  &-outline-black {
    @extend .btn;

    @include outlineButtonColors(#000, 20, 20);
  }
}
