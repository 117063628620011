.NotificationCookies {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2000;
    background-color: #fff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 10px 60px 10px 20px;
    display: flex;
    flex-direction: column;
}

.NotificationCookies__close {
    display: inline-block;
    text-align: right;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0px;
}

.NotificationCookies__close svg {
    transition: transform 0.2s ease-in-out;
}

.NotificationCookies__close:hover svg {
    cursor: pointer;
    transform: rotate(90deg);
}

.NotificationCookies__mainText {
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    white-space: nowrap;
}

.NotificationCookies__linkText {
    color: #9130DE;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    white-space: nowrap;
}

.NotificationCookies__linkText:hover {
    color: #9130DE;
    opacity: 0.6;
}

@media (min-width: 576px) {
    .NotificationCookies {
        flex-direction: row;
    }
}
