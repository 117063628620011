.CompleteBriefForm {
  margin-bottom: 73px;
  position: relative;
}

.CompleteBriefForm .ant-form-item-label > label::after {
  display: none;
}

.CompleteBriefForm__left-svg-vector {
  position: absolute;
  top: 50%;
  left: -120px;
  transform: translateY(-50%);
}

.CompleteBriefForm__right-svg-vector {
  position: absolute;
  top: 50%;
  right: -120px;
}

.CompleteBriefForm__left-star-small {
  position: absolute;
  bottom: 50px;
  left: -50px;
}

.CompleteBriefForm__right-vector-top {
  position: absolute;
  top: -20px;
  right: -60px;
}

.CompleteBriefForm__right-vector-bottom {
  position: absolute;
  bottom: 50px;
  right: -60px;
}

.CompleteBriefForm .ant-form-item {
  flex-direction: column;
  margin-bottom: 30px;
}

.CompleteBriefForm .ant-form-item input,
.CompleteBriefForm .ant-form-item textarea {
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.38px;
  padding: 12px 20px;
}

.CompleteBriefForm .ant-input-number {
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.38px;
  padding: 8px 20px;
  width: 100%;
}

.CompleteBriefForm .ant-input-number input {
  padding: 0;
}

.CompleteBriefForm .ant-input-number-handler-wrap {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.CompleteBriefForm .ant-form-item-label {
  display: block;
  align-self: flex-start;
}

.CompleteBriefForm .ant-form-item-label > label {
  color: #A9A9A9;
  margin-bottom: 10px;
}

.CompleteBriefForm .ant-btn {
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 11px 40px;
  height: auto;
}

.CompleteBriefForm .ant-btn-primary {
  background-color: #2C3FEE;
  border-color: #2C3FEE;
}

.CompleteBriefForm .ant-btn-primary:hover {
  background-color: #5665f1;
  border-color: #5665f1;
}

.CompleteBriefForm__inner_flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 30px;
}

.CompleteBriefForm__inner_flex_left-big {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.CompleteBriefForm__inner_flex_left-big .button {
  margin-left: auto;
}

.CompleteBriefForm__button-container_flex {
  margin-bottom: 0;
}

.CompleteBriefForm__button-container_flex .ant-form-item-control-input {
  width: 100%;
  display: flex;
}

.CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
}

.CompleteBriefForm__inner_flex_left-big > .ant-form-item {
  margin-left: auto;
}

.CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content button {
  flex-grow: 1;
  flex-shrink: 1;
  width: 33.33%;
  display: flex;
  padding: 11px 10px;
  justify-content: center;
  overflow: hidden;
}

.CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content button:last-child {
  margin-right: 0;
}

.CompleteBriefForm__inner_flex .ant-form-item {
  margin-bottom: 0;
  flex-grow: 1;
}

.CompleteBriefForm__inner_flex_left-big .ant-form-item {
  margin-bottom: 0;
}

.CompleteBriefForm__inner_flex .ant-form-item:first-child {
  flex-grow: 1;
  margin-right: 20px;
}

.CompleteBriefForm__inner_flex_left-big .ant-form-item:first-child {
  flex-grow: 2;
  margin-right: 20px;
}

.CompleteBriefForm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.CompleteBriefForm .CompleteBriefForm__outline-button {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  margin-right: 15px;
}

.CompleteBriefForm .CompleteBriefForm__outline-button:focus {
  border: 1px solid #9130DE;
  color: #9130DE;
}

.CompleteBriefForm .CompleteBriefForm__outline-button:hover {
  border: 1px solid #A25EDA;
  color: #A25EDA;
}

.CompleteBriefForm .CompleteBriefForm__outline-button:active,
.CompleteBriefForm .CompleteBriefForm__outline-button.active {
  border: 1px solid #9130DE;
  color: #9130DE;
}

.CompleteBriefForm__inner_flex_left-big-icon-container {
  padding: 13px 19px;
  background-color: #F2F2F2;
  border-radius: 10px;
  margin-right: 15px;
}

.CompleteBriefForm__inner_flex_left-big-text-container {
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  color: #A9A9A9;
}

.CompleteBriefForm__checkbox-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

/* первое значение grid-row-start, второе grid-column-start , третье grid-row-end и четвёртое grid-column-end. */
.CompleteBriefForm__checkbox:nth-child(1) {
  grid-area: 1 / 1 / 2 / 4;
}

.CompleteBriefForm__checkbox:nth-child(2) {
  grid-area: 1 / 4 / 2 / 7;
}

.CompleteBriefForm__checkbox:nth-child(3) {
  grid-area: 2 / 1 / 3 / 3;
}

.CompleteBriefForm__checkbox:nth-child(4) {
  grid-area: 2 / 3 / 3 / 5;
}

.CompleteBriefForm__checkbox:nth-child(5) {
  grid-area: 2 / 5 / 3 / 7;
}

/* style for check mark inside checkbox outer div */
.CompleteBriefForm__checkbox.ant-checkbox-wrapper .ant-checkbox,
.CompleteBriefForm__checkbox.ant-checkbox-wrapper .ant-checkbox::after {
  visibility: hidden;
  width: 0;
  height: 0;
}

/* style for checkbox outer div */
.CompleteBriefForm__checkbox.ant-checkbox-wrapper {
  text-align: center;
  text-wrap: none;
  font-size: 20px;
  line-height: 25px;

  border-radius: 10px;
  border: 1px solid #d9d9d9;

  margin: 0 auto;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 54px;

  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.CompleteBriefForm__checkbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus {
  border: 1px solid #9130DE;
  color: #9130DE;
}

.CompleteBriefForm__checkbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
  border: 1px solid #A25EDA;
  color: #A25EDA;
}

.CompleteBriefForm__checkbox.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled),
.CompleteBriefForm__checkbox.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover {
  border: 1px solid #9130DE;
  color: #9130DE;
}

/* style for error text under inputs */
.CompleteBriefForm .ant-form-item-explain {
  margin-top: 5px;
}

@media (max-width: 991px) {
  .CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content {
    flex-direction: column;
  }

  .CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .CompleteBriefForm__button-container_flex .ant-form-item-control .ant-form-item-control-input-content button:last-child {
    margin-bottom: 0;
  }

  .CompleteBriefForm__inner_flex_left-big {
    flex-wrap: wrap;
  }

  .CompleteBriefForm__inner_flex_left-big > .ant-form-item {
    margin-top: 15px;
    margin-left: 0;
    width: 100%;
  }

  .CompleteBriefForm__checkbox-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  .CompleteBriefForm__checkbox:not(:last-child) {
    margin-bottom: 10px;
  }

  .CompleteBriefForm__checkbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
    border: 1px solid #d9d9d9;
    color: #000000;
  }

  .CompleteBriefForm__checkbox.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover {
    border: 1px solid #9130DE;
    color: #9130DE;
  }

  .CompleteBriefForm__checkbox.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
    border: 1px solid #9130DE;
    color: #9130DE;
  }

  .CompleteBriefForm__checkbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus {
    border: 1px solid #9130DE;
    color: #9130DE;
  }

  .CompleteBriefForm__left-svg-vector,
  .CompleteBriefForm__right-svg-vector,
  .CompleteBriefForm__left-star-small,
  .CompleteBriefForm__right-vector-top,
  .CompleteBriefForm__right-vector-bottom {
    display: none;
  }

  .CompleteBriefForm__submit-button {
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .CompleteBriefForm__inner_flex {
    display: flex;
    flex-direction: column;
  }

  .CompleteBriefForm__inner_flex .ant-form-item {
    width: 100%;
  }

  .CompleteBriefForm__inner_flex .ant-form-item:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}


