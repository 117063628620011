
.plan .large-text {
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 100%;
  letter-spacing: -3px;
  color: #000000;
}
.plan .big-link {
  font-size: 18px;
}
.plan .wrapper {
  padding: 25px;
}
.plans {
  padding-top: 65px;
  overflow: hidden;
}
.plans .tabs {
  padding-top: 35px;
}
.plans .plan img {
  width: auto;
}
.plans .wrapper-container {
  padding-top: 65px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  max-width: 1100px;
  margin: 0 auto;
}
.plans .info {
  padding-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #7C7C7C;
  max-width: 449px;
  margin: auto;
}
.plans .subtitle{
  padding-top: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #7C7C7C;
  max-width: 245px;
  margin: auto;
}

.plans .black-friday-text {
  padding: 24px 0 0;
  text-align: center;
}

.plan .wrapper {
  padding: 30px;

  display: grid;
  border-radius: 10px;
  border: 1px solid transparent;
}
.plan.starter .wrapper {
  border-color: #E7E7E7;
}
.plan.creator .wrapper {
  background: #FFDF57;
}
.plan.creator .description {
  color: rgba(0, 0, 0, 0.5);
}
.plan.pro .wrapper {
  background: #000000;
}
.plan.pro .description {
  color: rgba(255, 255, 255, 0.6);
}
.plan.pro .large-text,
.plan.pro .badge,
.plan.pro .short-description,
.plan.pro .total {
  color: #ffffff;
}
.plan .first {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plan .second {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.plan .third {
  padding-top: 16px;
}
.plan .fourth {
  padding-top: 25px;
}
.plan .total {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}
.plan .large-text {
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 100%;

  letter-spacing: -3px;
  color: #000000;
}
.plan .short-description {
  max-width: 100px;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.plan .description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #7C7C7C;
}
.plan.creator .badge {
  background: rgba(0, 0, 0, 0.1);
}
.plan.pro .badge {
  background: rgba(255, 255, 255, 0.2);
}
.plans .MuiSkeleton-root {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
  min-height: 365px;
}

@media (min-width: 992px) {

  /*plans.html*/
  .plans .element-01 {
    left: -10px;
    top: 60px;
  }
  .plans .element-02 {
    left: -20px;
    bottom: -360px;
  }
  .plans .element-03 {
    left: 250px;
    top: 30px;
  }
  .plans .element-04 {
    left: 270px;
    bottom: -370px;
  }
  .plans .element-05 {
    right: 300px;
    bottom: -70px;
  }
  .plans .element-06 {
    right: 300px;
    top: 200px;
  }
  .plans .element-07 {
    right: 300px;
    bottom: -350px;
  }
  .plans .element-08 {
    right: 10px;
    bottom: -70px;
  }
  .plans .element-09 {
    right: 0;
    top: 320px;
  }
}

@media only screen and (min-width: 1270px) {
  /*plans.html*/
  .plans .element-01 {
    left: 30px;
    top: 60px;
  }
  .plans .element-02 {
    left: 10px;
    bottom: -420px;
  }
  .plans .element-03 {
    left: 380px;
    top: 30px;
  }
  .plans .element-04 {
    left: 350px;
    bottom: -400px;
  }
  .plans .element-05 {
    right: 400px;
    bottom: -70px;
  }
  .plans .element-06 {
    right: 410px;
    top: 200px;
  }
  .plans .element-07 {
    right: 380px;
    bottom: -380px;
  }
  .plans .element-08 {
    right: 10px;
    bottom: -70px;
  }
  .plans .element-09 {
    right: 0;
    top: 320px;
  }

}

@media only screen and (min-width: 1510px) {
  .plans .element-01 {
    left: 170px;
    top: 60px;
  }
  .plans .element-02 {
    left: 150px;
    bottom: -380px;
  }
  .plans .element-03 {
    left: 520px;
    top: 30px;
  }
  .plans .element-04 {
    left: 530px;
    bottom: -380px;
  }
  .plans .element-05 {
    right: 530px;
    bottom: -70px;
  }
  .plans .element-06 {
    right: 550px;
    top: 260px;
  }
  .plans .element-07 {
    right: 520px;
    bottom: -390px;
  }
  .plans .element-08 {
    right: 150px;
    bottom: -70px;
  }
  .plans .element-09 {
    right: 130px;
    top: 320px;
  }
}

@media (max-width: 991px) {
  .plans .wrapper-container {
    grid-template-columns: 1fr;
  }

  .plans .element-01,
  .plans .element-02,
  .plans .element-03,
  .plans .element-04,
  .plans .element-05,
  .plans .element-06,
  .plans .element-07,
  .plans .element-08,
  .plans .element-09 {
    display: none;
  }

  .plans .MuiSkeleton-root > div {
    padding-top: 65% !important;
  }

  .plans .wrapper-container {
    grid-template-columns: 1fr;
  }
}
