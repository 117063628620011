.tableWrapper {
  width: calc(100vw - 45px);
  overflow-x: auto;
}

@media (min-width: 576px) {
  .tableWrapper {
    width: 100%;
  }
}
