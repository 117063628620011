.AboutUs {
  margin-top: 90px;
}

.AboutUs__heading {
  margin-bottom: 140px;
}

.AboutUs__heading-text-column {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AboutUs__heading .title_medium {
  margin-bottom: 34px;
}

.AboutUs__heading p {
  margin-bottom: 20px;
  color: #000;
}

.AboutUs__heading p:last-child {
  margin-bottom: 0;
}

.AboutUs__heading picture,
.AboutUs__heading img {
  width: 100%;
}

.AboutUs__pros {
  margin-bottom: 80px;
}

.AboutUs__pros .AboutUsProsCard {
  background-color: #F2F2F2;
  height: 100%;
}

.AboutUs__pros > div {
  margin-bottom: 30px;
}

.AboutUs__pros > div:nth-child(6n - 5) .AboutUsProsCard {
  background-color: #F6F0FF;
}

.AboutUs__pros > div:nth-child(6n - 4) .AboutUsProsCard {
  background-color: #FCE6E6;
}

.AboutUs__pros > div:nth-child(6n - 3) .AboutUsProsCard {
  background-color:  #DFFFE6;
}

.AboutUs__pros > div:nth-child(6n - 2) .AboutUsProsCard {
  background-color:  #FEF8D7;
}

.AboutUs__pros > div:nth-child(6n - 1) .AboutUsProsCard {
  background-color:  #EFEFEF;
}

.AboutUs__pros > div:nth-child(6n) .AboutUsProsCard {
  background-color:  #DFFDFF;
}

.AboutUs-resources__title {
  margin-bottom: 34px;
}

.AboutUs-resources__subtitle {
  margin-bottom: 63px;
}

.AboutUs-resources-list {
  justify-content: center;
}

.AboutUs-resources-list .ResourceCard {
  margin: 0 30px 30px;
  max-width: calc(100% - 60px);
}

@media (min-width: 480px) {
  .AboutUs-resources-list .ResourceCard {
    max-width: 350px;
  }
}

@media (min-width: 835px) {
  .AboutUs-resources-list .ResourceCard:nth-child(2) {
    margin-left: 0;
  }
}

@media (min-width: 991px) {
  .AboutUs__heading-text-column {
    margin-bottom: 0;
  }

  .AboutUs__heading {
    margin-bottom: 140px;
  }

  .AboutUs__pros {
    margin-bottom: 140px;
  }
}

@media (min-width: 1200px) {
  .AboutUs-resources-list .ResourceCard {
    margin: 0 30px 30px 0;
  }

  .AboutUs-resources-list .ResourceCard:last-child {
    margin-right: 0;
  }
}
