.OrderCustom {
  padding-top: 109px;
  overflow: hidden;
}

.OrderCustom__head-container {
  max-width: 1600px;
  margin: 0 auto;
}

.OrderCustom__title {
  margin-bottom: 30px;
  position: relative;
}

.OrderCustom__title-svg_left {
  position: absolute;
  left: -60px;
  top: -60px;
}

.OrderCustom__title-svg_right {
  position: absolute;
  right: -40px;
  top: -50px;
}

.OrderCustom__title-svg_top_left {
  position: absolute;
  top: -80px;
  left: 22.5%;
}

.OrderCustom__title-svg_top_right {
  position: absolute;
  top: -80px;
  right: 32%;
}

.OrderCustom__main-description {
  position: relative;
}

.OrderCustom__main-description span:first-child {
  position: absolute;
  left: -30px;
  bottom: 0;
}

.OrderCustom__main-description span:last-child {
  position: absolute;
  right: -100px;
  bottom: 0;
  transform: rotateX(180deg) rotateZ(180deg);
}

.OrderCustom__title_secondary {
  margin-bottom: 20px;
}

.OrderCustom__formlink {
  margin: 40px auto 12px;
}

.OrderCustom__headind-text_small_colored {
  font-size: 16px;
  line-height: 24px;
  color: #2C3FEE;
}

.OrderCustom__types-section {
  max-width: 1600px;
  margin: 160px auto 0;
}

.OrderCustom__types-section-subtitle {
  max-width: 536px;
  margin: 0 auto 70px;
}

.OrderCustom__types-section picture {
  display: block;
  width: 100%;
}

.OrderCustom__types-section picture img {
  width: 100%;
  display: inline-block;
  height: auto;
}

.OrderCustom__types-section-item_small-image {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.OrderCustom__types-section-item_small-image picture {
  width: 92%;
}

.OrderCustom__types-section-item {
  border-bottom-left-radius: 30% 50%;
  border-bottom-right-radius: 30% 50%;
  border-top-left-radius: 30% 50%;
  border-top-right-radius: 30% 50%;
}

.OrderCustom__types-section-item_pink {
  background-color: #FFF0F5;
  border-bottom-left-radius: 30% 50%;
  border-bottom-right-radius: 30% 50%;
  border-top-left-radius: 30% 50%;
  border-top-right-radius: 30% 50%;
}

.OrderCustom__types-section-item_gray {
  background-color: #F8FAFD;
  border-bottom-left-radius: 30% 50%;
  border-bottom-right-radius: 30% 50%;
  border-top-left-radius: 30% 50%;
  border-top-right-radius: 30% 50%;
}

.OrderCustom__types-section-item_bordered{
  border: 1px solid #CDCDCD;
  border-bottom-left-radius: 30% 50%;
  border-bottom-right-radius: 30% 50%;
  border-top-left-radius: 30% 50%;
  border-top-right-radius: 30% 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.OrderCustom__types-section-item_bordered video {
  width: 80%;
  height: auto;
  margin: auto;
}

.OrderCustom__types-section-row {
  margin-bottom: 30px;
}

.OrderCustom__types-section-text {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.OrderCustom__types-section-text_secondary {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 200px;
}

.OrderCustom__types-section-text .row div:first-child {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -2px;
  font-weight: 600;
  color: #000;
}

.OrderCustom__types-section-text_secondary div:first-child {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -2px;
  font-weight: 600;
  color: #000;
  margin-right: 30px;
}

.OrderCustom__work-process-section {
  max-width: 1600px;
  margin: 110px auto 0;
}

.OrderCustom__work-process-section-inner-row {
  position: relative;
  margin-bottom: 140px;
}

.OrderCustom__work-process-section-inner-item,
.OrderCustom__work-process-section-inner-item_yellow,
.OrderCustom__work-process-section-inner-item_green,
.OrderCustom__work-process-section-inner-item_blue,
.OrderCustom__work-process-section-inner-item_magnolia {
  padding: 76px 70px;
}

.OrderCustom__work-process-section-inner-item h3,
.OrderCustom__work-process-section-inner-item_yellow h3,
.OrderCustom__work-process-section-inner-item_green h3,
.OrderCustom__work-process-section-inner-item_blue h3,
.OrderCustom__work-process-section-inner-item_magnolia h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
  position: relative;
  z-index: 11;
}

.OrderCustom__work-process-section-inner-item p,
.OrderCustom__work-process-section-inner-item_yellow p,
.OrderCustom__work-process-section-inner-item_green p,
.OrderCustom__work-process-section-inner-item_blue p,
.OrderCustom__work-process-section-inner-item_magnolia p {
  color: #000;
  position: relative;
  z-index: 11;
}

.OrderCustom__work-process-section-inner-item_yellow {
  background-color: #FEFFDF;
}

.OrderCustom__work-process-section-inner-item_green {
  background-color: #DFFFE6;
}

.OrderCustom__work-process-section-inner-item_blue {
  background-color: #DFFDFF;
}

.OrderCustom__work-process-section-inner-item_magnolia {
  background-color: #F6F0FF;
}

.OrderCustom__work-process-section-img {
  position: absolute;
}

.OrderCustom__work-process-section-img_left {
  position: absolute;
  display: block;
  top: 50%;
  left: 11%;
}

.OrderCustom__work-process-section-img_right {
  position: absolute;
  display: block;
  top: 50%;
  right: 12%;
}

.OrderCustom__work-process-section-item-img_right {
  position: absolute;
  height: 110%;
  top: 50%;
  right: 50%;
  transform: translate(90%, -50%);
  z-index: 10;
}

.OrderCustom__work-process-section-item-img_left {
  position: absolute;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -50%);
  z-index: 10;
}

.OrderCustom__complete-a-brief-section {
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 40px;
}

.OrderCustom__complete-a-brief-section .OrderCustom__title_secondary {
  position: relative;
}

.OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:first-child {
  position: absolute;
  bottom: -15px;
  left: 0;
}

.OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:last-child {
  position: absolute;
  top: -60px;
  right: 0;
}

@media (max-width: 991px) {
  .OrderCustom__title-svg_left {
    left: -15px;
  }

  .OrderCustom__title-svg_right {
    right: -10px;
  }

  .OrderCustom__main-description span:first-child,
  .OrderCustom__main-description span:last-child {
    display: none;
  }

  .OrderCustom__types-section-text_secondary {
    flex-direction: column;
    margin-bottom: 96px;
  }

  .OrderCustom__work-process-section-img_left,
  .OrderCustom__work-process-section-img_right {
    display: none;
  }

  .OrderCustom__work-process-section-inner-row {
    margin-bottom: 83px;
    padding: 0 53px;
  }

  .OrderCustom__work-process-section-inner-item,
  .OrderCustom__work-process-section-inner-item_yellow,
  .OrderCustom__work-process-section-inner-item_green,
  .OrderCustom__work-process-section-inner-item_blue,
  .OrderCustom__work-process-section-inner-item_magnolia {
    padding: 34px 34px;
  }

  .OrderCustom__work-process-section-item-img_right {
    position: absolute;
    height: 110%;
    top: 50%;
    right: 50%;
    transform: translate(90%, -50%);
    z-index: 10;
  }

  .OrderCustom__work-process-section-item-img_left {
    position: absolute;
    height: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-90%, -50%);
    z-index: 10;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:first-child {
    left: -30px;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:last-child {
    right: -30px;
  }
}

@media (max-width: 840px) {
  .OrderCustom {
    padding-top: 70px;
  }

  .OrderCustom__title-svg_top_left,
  .OrderCustom__title-svg_top_right {
    display: none;
  }

  .OrderCustom__title-svg_left {
    display: none;
  }

  .OrderCustom__title-svg_right {
    display: none;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:first-child {
    left: -50px;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:last-child {
    right: -50px;
  }
}

@media (max-width: 768px) {
  .OrderCustom__types-section-text_secondary div:first-child ,
  .OrderCustom__types-section-text .row div:first-child {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: normal;
  }

  .OrderCustom__work-process-section-item-img_right {
    height: 60%;
  }

  .OrderCustom__work-process-section-item-img_left {
    height: 60%;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:first-child {
    left: 0;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:last-child {
    right: 0;
  }
}

@media (max-width: 576px) {
  .OrderCustom__work-process-section-inner-row {
    padding: 0 30px;
  }

  .OrderCustom__work-process-section-item-img_right {
    position: relative;
    width: 80%;
    height: auto;
    top: 0;
    left: 0;
    transform: none;
    margin: 30px auto 0;
  }

  .OrderCustom__work-process-section-item-img_left {
    position: relative;
    width: 80%;
    height: auto;
    top: 0;
    left: 0;
    transform: none;
    margin: 30px auto 0;
  }

  .OrderCustom__work-process-section-inner-item h3,
  .OrderCustom__work-process-section-inner-item_yellow h3,
  .OrderCustom__work-process-section-inner-item_green h3,
  .OrderCustom__work-process-section-inner-item_blue h3,
  .OrderCustom__work-process-section-inner-item_magnolia h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: normal;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:first-child {
    display: none;
  }

  .OrderCustom__complete-a-brief-section .OrderCustom__title_secondary span:last-child {
    display: none;
  }
}
