/*widget*/
.browse-by-card img {
    width: 110px;
    padding-top: 20px;
}

.browse-by-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    height: 200px;
    width: 150px;
    border-radius: 10px;
    box-sizing: border-box;
}

.browse-by-card:hover {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
}

.browse-by-card .description {
    padding-top: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;

    color: #000000;
}

.browse-by-card.browse-by-card-3d {
    background: #FFEFD0;
}

.browse-by-card.browse-by-card-3d:hover {
    box-shadow: 0px 10px 50px rgba(240, 167, 25, 0.2);
}

.browse-by-card.browse-by-card_web {
    background: #E2F5F8 !important;
}

.browse-by-card.browse-by-card_backgrounds {
    background: #E2F5F8;
}

.browse-by-card.browse-by-card_backgrounds:hover {
    box-shadow: 0 10px 50px rgb(226, 245, 248, 0.2);
}

.browse-by-card.browse-by-card_web:hover {
    box-shadow: 0 10px 50px rgb(226, 245, 248, 0.2) !important;
}

.browse-by-card.browse-by-card_icons {
    background: #E7F7CC;
}

.browse-by-card.browse-by-card_icons:hover {
    box-shadow: 0 10px 50px rgb(231, 247, 204, 0.2);
}

.browse-by-card.browse-by-card_elements {
    background: #E7F7CC;
}

.browse-by-card.browse-by-card_elements:hover {
    box-shadow: 0 10px 50px rgb(231, 247, 204, 0.2);
}


.browse-by-card.browse-by-card-outline {
    background: #F3ECFF;
}

.browse-by-card.browse-by-card-outline:hover {
    box-shadow: 0px 10px 50px rgba(21, 29, 230, 0.15);
}

.browse-by-card.browse-by-card-flat {
    background: #E0FFF8;
}

.browse-by-card.browse-by-card-flat:hover {
    box-shadow: 0px 10px 50px rgba(18, 169, 135, 0.2);
}

.browse-by-card.browse-by-card-grainy {
    background-color: #F1F1F1;
}

.browse-by-card.browse-by-card-grainy:hover {
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}

.browse-by-card.browse-by-card-handdrawn {
    background-color: #FFEEEE;
}

.browse-by-card.browse-by-card-handdrawn:hover {
    box-shadow: 0px 10px 50px rgba(238, 24, 24, 0.1);
}

@media (max-width: 767px) {
    .browse-by-card {
        width: 140px;
        height: 190px;
    }
}
