/*header*/
.page-header .nav-item-authorized {
  display: none;
}
.page-header.authorized .nav-item-auth {
  display: none;
}
.page-header.authorized .nav-item-authorized {
  display: block;
}

/**/
.page-header form .form-control {
  padding-left: 25px;
  padding-right: 0;
  height: 60px;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  border: none;
  outline: 0 !important;
  -webkit-appearance: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form {
  position: relative;
}
.page-header form:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 0;
  /*background-image: url("/src/img/icon-search.svg");*/
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}
.page-header form .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-header form .form-control:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-header form .form-control:focus:-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-header form .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-header form .form-control::-webkit-input-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control::-moz-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control:-ms-input-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control::-ms-input-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control::placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control::-webkit-input-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-header form .form-control::-moz-placeholder {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/**/
/**/

.navbar .wrapper {
  display: flex;
  align-items: center;
}
.navbar {
  padding: 20px 16px;
  max-height: 64px;
  position: relative;
  z-index: 100;
}

.navbar-brand-mr {
  margin-right: 32px;
  padding: 0 !important;
}

.cfw-button-ghost {
  border-radius: 8px;
  border: 1px solid rgba(47, 43, 67, 0.1);
  padding: 3px 12px;
  background: #fff;
  /* Shadows/1 */
  box-shadow: 0 -1px 0 0 rgba(47, 43, 67, 0.1) inset,
    0 1px 3px 0 rgba(47, 43, 67, 0.1);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.navbar .nav-item-hidden {
  display: none;
}

.nav-item-authorized > .nav-link {
  line-height: 0 !important;
}

.navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
  background-color: #fff;
  padding: 20px;
}
.navbar-nav .dropdown-toggle::after {
  display: none;
}
.navbar-nav .nav-link {
  padding: 4px 0 !important;
  border: none;
  background-color: transparent;

  color: rgba(47, 43, 67, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}
.navbar-nav .nav-link.red {
  color: #fe3434 !important;
}
.navbar-nav .nav-link.red:hover {
  color: rgba(243, 6, 89, 0.8) !important;
}
.navbar-nav .nav-link.colored {
  height: 27px;
  padding: 0 6px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
  border-radius: 5px;

  color: #ffffff !important;
}
.navbar-nav .nav-link.blue {
  background-color: #2c3fee;
}
.navbar-nav .nav-link.blue:hover {
  background-color: rgba(44, 63, 238, 0.85);
}
.navbar-nav .nav-link.outline {
  display: flex;
  height: 27px;
  max-width: max-content;

  align-items: center;
  justify-content: center;
  padding: 0 6px !important;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
  border-radius: 5px;
}
.navbar-nav .nav-link.outline.blue {
  border: 1px solid #2c3fee;
  color: #2c3fee !important;
}
.navbar-nav .nav-link.outline.blue:hover {
  background-color: #2c3fee;
  color: #ffffff !important;
}
.navbar-nav .nav-item {
  margin-right: 24px;
}

.navbar-right-block {
  justify-content: end !important;
}

.navbar-nav .nav-item-custom {
  padding: 0 10px;
  max-height: 50px;
}
.navbar-nav .badge {
  padding: 0 6px;

  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  color: #000000;
  border-radius: 5px;
}
.navbar-nav .badge.green {
  background: #25ea5c;
}
.navbar-nav .badge.blue {
  background: #2c3fee;
  color: #ffffff;
}
.navbar-nav .nav-link > .badge {
  margin-left: 8px;
}
.navbar-nav .avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.navbar .dropdown:hover .dropdown-menu {
  display: block;
}
.navbar .navbar-toggler {
  margin-left: auto;
}

/*.navbar-nav .nav-item {*/
/*    padding-bottom: 10px;*/
/*}*/
.navbar .dropdown-update:hover .dropdown-menu {
  display: none;
}
/*.navbar .dropdown:hover .dropdown-menu {*/
/*    margin-top: 10px;*/
/*}*/

.header-tools .downloads-count {
  padding: 24px 0 19px;
}

.header-tools .dropdown-menu {
  padding: 24px 0 19px;
  background-color: #fff;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 0;
  left: -100px;
  margin-top: -2px;
  display: none;
}
.header-tools .browse-content {
  left: -50px;
}
.header-tools .logged-author-content {
  padding-bottom: 0;
}
/*.navbar .dropdown:hover .dropdown-menu {*/
/*  display: block;*/
/*}*/
.event-wrapper:hover {
  background: #f8f8f8;
  color: #747474 !important;
}
.header-tools .dropdown-menu.update-content {
  padding: 0;
  height: 577px;
  min-width: 441px;
  overflow: hidden;
}
.update-content-inner {
  height: 521px;
  overflow-y: scroll;
}
.header-tools .downloads-count {
  color: #2c3fee;
  padding-left: 25px;
  padding-bottom: 25px;
}
.header-tools .downloads-count .total {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;

  letter-spacing: -0.5px;
  color: #2c3fee;
}
.header-tools .downloads-count .description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.header-tools .dropdown-menu.browse-content,
.header-tools .dropdown-menu.logged-author-content,
.header-tools .dropdown-menu.logged-pro-author-content {
  width: 185px;
  padding-bottom: 0;
}
.header-tools .dropdown-menu.logged-pro-content {
  width: 190px;
  left: -125px;
  padding-bottom: 0;
}
.header-tools .dropdown-menu .bordered-list {
  margin: 0;
  padding: 0;
  list-style: none;

  padding-top: 18px !important;
  padding-bottom: 18px !important;
  border-top: 1px solid #f2f2f2;
}

.dropdown-menu .bordered-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.browse-content-inner > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.event-wrapper {
  padding: 15px 20px;
  border-bottom: 1px solid #f2f2f2;
}
.header-tools > ul > li a {
  line-height: 23px;
  font-size: 16px;
  color: #000;
}
.update-content-inner a:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.update-content-inner .event-top {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 15px;
}
.cat-event {
  height: 18px;
  line-height: 18px;
  padding: 1px 5px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 8px;
}
.cat-event.cat-new {
  background: #25ea5c;
  color: #000000;
}
.event-top strong {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000;
}
.event-miidle p {
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7c7c7c;
}
.count-product {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}
.update-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7c7c7c;
}
.full_update {
  border-top: 1px solid #f2f2f2;
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.header-tools .full_update a {
  height: 55px;
  line-height: 55px;
  font-weight: 500;
  font-size: 16px;
  color: #3056de;
}
.header-tools .full_update a:hover {
  background: rgba(48, 86, 222, 0.1);
  color: #3056de !important;
}
.header-tools .dropdown-menu .nav-link {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-bottom: 10px !important;
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header-tools .dropdown-menu .nav-link:last-of-type {
  padding-bottom: 14px !important;
}

.header-tools .dropdown-menu .bordered-list li:nth-last-of-type(1) .nav-link {
  padding-bottom: 0 !important;
}
.header-tools .dropdown-menu .nav-link.bordered {
  padding-top: 14px !important;
  padding-bottom: 18px !important;
  border-top: 1px solid #f2f2f2;
}

/*ant*/
.header-tools .ant-collapse-content-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-tools .ant-collapse-content-box .nav-link {
  padding-bottom: 10px !important;
}

.header-tools .ant-collapse-header {
  padding: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000 !important;
}

.header-tools .ant-collapse-content-box {
  padding: 0 !important;
  padding-top: 20px !important;
}

@media screen and (max-width: 991px) {
  .navbar-nav {
    align-items: flex-start;
  }

  .navbar .wrapper {
    width: 100%;
  }
  .navbar {
    padding: 6px 15px;
  }

  .page-header .nav-item-authorized {
    display: block;
  }

  .navbar .dropdown .dropdown-menu {
    /*display: block;*/
    width: 100%;
    box-shadow: none;
  }

  .page-header .user-profile {
    padding-top: 25px;
  }

  .user-profile > .nav-link {
    display: none;
  }

  .navbar .logged-author-content {
    display: block;
  }

  .navbar .dropdown-browse {
    display: none;
  }

  .page-header.authorized .nav-item-authorized {
    width: 100%;
  }

  .header-tools .downloads-count {
    padding-top: 0;
    padding-left: 0;
  }

  .header-tools .dropdown-menu .nav-link {
    padding-left: 0 !important;
  }

  .header-tools .dropdown-menu {
    padding-top: 0;
  }

  .navbar .nav-item-hidden {
    display: block;
  }

  .navbar-nav .nav-item {
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  .navbar .wrapper {
    display: grid;
    grid-template-columns: auto 55px;
    grid-template-rows: auto auto;
    width: 100%;
  }
  .navbar .navbar-toggler {
    grid-column: 2/-1;
    grid-row: 1;
  }
}
