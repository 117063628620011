.advantages-tiles {
 
  &__title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #000000;
  }

  &__container {
    margin-top: 30px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}

@media (max-width: 1269px) {
  .advantages-tiles {
    padding: 0 15px !important;
  }
}

@media (max-width: 991px) {
  .advantages-tiles {
    &__container {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
}
