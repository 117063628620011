.empty-data {
    max-width: 400px;
    margin: 50px auto 0;
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty-data button {
    margin-top: 40px;
    font-weight: 600;
}

.empty-data img {
    width: 100%;
}

.empty-data .description {
    margin-top: 30px;

    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #000000;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .empty-data {
        margin-top: 100px;
    }
}

@media screen and (max-width: 400px) {
    .empty-data {
        margin-top: 0;
    }
}
