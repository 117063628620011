 .browse-by-illustrations .header {
   display: flex;
   justify-content: center;
 }

 .browse-by-illustrations .body {
   margin-top: 30px;
 }

 .browse-by-illustrations .footer {
   margin-top: 40px;
 }

 .browse-by-illustrations .MuiSkeleton-root {
   border-radius: 10px;
   background-color: rgba(0, 0, 0, 0);
   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
 }
