.single-illustration-page  .button-col {
  margin-top: 20px;
}

.single-illustration-page  .text-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.single-illustration-page .button-col .MuiSkeleton-root {
  border-radius: 10px;
}

.single-illustration-page .product .link:hover {
  box-shadow: none !important;
}

.single-illustration-page .MuiSkeleton-root {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
}
