/**/
.faq .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.faq .left,
.faq .right {
  display: grid;
  grid-gap: 40px;
}
.faq .title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: #000000;
}
.faq .description {
  padding-top: 20px;
}
.faq .item {
  padding-left: 30px;
  padding-right: 70px;
}

@media (max-width: 991px) {
  .faq .item {
    padding-left: 0;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .faq .wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 575px) {
  .faq .item {
    padding-left: 0;
    padding-right: 0;
  }
  .faq .left,
  .faq .right {
    display: grid;
    grid-gap: 30px;
  }
}