.textpage {
  margin-top: 65px;
  margin-bottom: 100px;
}

.textpage__title,
.textpage__text_lead {
  font-weight: 400;
  padding: 0;
}
.textpage__title_secondary {
  font-weight: 600;
  padding: 0;
}

.textpage__title {
  font-size: 62px;
  line-height: 70px;
  letter-spacing: -3px;
  margin-bottom: 20px;
}

.textpage__title_secondary {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.textpage__text_lead {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: normal;
  margin-top: 50px;
  margin-bottom: 20px;
}

.textpage p {
  margin-bottom: 20px;
}

.textpage ul {
  list-style: disc;
  padding-left: 40px;
}

.textpage ul li {
  padding-left: 10px;
  margin-bottom: 12px;
}

.textpage a {
  display: inline-block;
  position: relative;
  color: #7C7C7C;
}

.textpage a::after {
  display: block;
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #7C7C7C;
}

.textpage a:hover {
  color: #000;
}

.textpage a:hover::after {
  background-color: #000;
}

@media (max-width: 991px) {
  .textpage__title {
    font-size: 54px;
    line-height: 60px;
    letter-spacing: -2px;
  }
  .textpage__title_secondary {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: normal;
  }
}
