.pack-card {

  &__tile {
    position: relative;
    padding-bottom: 75%;
    cursor: pointer;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-color: rgba(255, 255, 255, 0);
    border-width: 0;
    background-color: rgba(255, 255, 255, 0);
    background-clip: content-box;

  }

  &__link:hover {
    box-shadow: 0px 12px 24px 0px rgba(47, 43, 67, 0.08);
    transition: 150ms;
  }

  &__link {
    transition: 150ms;
    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10);
  }

  &__link:hover &__asset,
  &__link:hover &__tile {
    position: relative;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .07);
    box-shadow: 0 20px 50px rgba(0, 0, 0, .07);
    z-index: 90;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    background-clip: content-box;
  }

  &__tile &__tile-shade {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  &__picture-container {
    height: 100%;
    width: 100%;
    padding: 0;
    border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;

    -webkit-box-sizing: border-box;
    overflow: hidden;
    box-sizing: border-box;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    //border-style: solid;
    //border-color: rgba(255,255,255,0);
    //border-width: 0;

    background-color: rgba(255, 255, 255, 0);
    background-clip: content-box;
    /* this is for safari for border-radius to work with overflow: hidden --> */
    transform: translateZ(0);
    /* <-- */
  }

  &__assets-container {
    //border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //gap: 2px;
    top: 0;
  }


  &__picture,
  &__image {
    width: 100%;
    height: initial;
    z-index: 30;
  }

  &__picture-container:after {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  &__tile-shade {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //border-color: rgba(255,255,255,0);
    //border-width: 0;
    border-top: none;
    z-index: 100;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    pointer-events: none;

    &_gray {
      //border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));
      //background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
      opacity: 1;
    }
  }


  &__asset {
    position: relative;
    cursor: pointer;
    padding-bottom: 75%;
    transform: translateZ(0);
    background-clip: content-box;
  }

  &__asset:after {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
    display: none;
  }

  &__asset-container {
    height: 100%;
    width: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;

    -webkit-box-sizing: border-box;
    overflow: hidden;
    box-sizing: border-box;
    border-width: 0;
    background-clip: content-box;
  }

  &__assets-container {
    //margin-top: 2px;
  }

  &__asset:first-of-type {
    border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));
    border-right: none;
    border-top: none;
  }

  &__asset:nth-child(2) {
    border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));
    border-top: none;
  }

  &__asset:last-of-type {
    border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));
    border-left: none;
    border-top: none;
  }

  &__asset:first-of-type,
  &__asset:first-of-type &__tile-shade {
    border-bottom-left-radius: 15px;
  }

  &__asset:last-of-type,
  &__asset:last-of-type &__tile-shade {
    border-bottom-right-radius: 15px;
  }

  &__asset:first-of-type,
  &__asset:last-of-type {
    overflow: hidden;
  }

  &__about {
    margin-top: 15px;
    display: flex;
    flex-flow: row nowrap;
    align-content: start;
  }

  &__thumbnail-container {
    position: relative;
    margin-right: 14px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    outline-offset: -1px;
    overflow: hidden;
    flex: 0 0 56px;
  }

  &__thumbnail-container:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset;
    background-color: transparent;
    z-index: 11;
  }

  &__thumbnail-container:hover {
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, .15);
    box-shadow: 0 20px 50px rgba(0, 0, 0, .15);
    z-index: 90;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    background-clip: content-box;
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__texts-container {
    flex: 1 1 auto;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    vertical-align: baseline;
    margin-top: 6px;
  }

  &__header-text {
    font-style: normal;
    color: #7C7C7C;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    padding: 0 10px 0 0;
    flex: 1 1 auto;
  }

  &__price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 2px 10px;
    border-radius: 58px;


    &_commercial {
      @extend .pack-card__price;
      color: #000000;
      background: #F8F8F8;
    }

    &_free {
      @extend .pack-card__price;
      color: #17B763;
      background: #E3F7DA;
    }

    &_before-discount {
      @extend .pack-card__price;
      font-style: normal;
      font-weight: 100;
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: line-through;
      text-decoration-thickness: 1px;
      color: #7C7C7C;
    }

    &_discounted {
      @extend .pack-card__price;
      color: #954700;
      background: #FDC686;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: #000000;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }

  &__title:hover {
    color: #7C7C7C;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7C7C7C;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
