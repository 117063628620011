.EmptyPageFiller {
  text-align: center;

  picture {
    display: block;
    width: 100%;
  }

  img {
    max-width: 100%;
    margin-bottom: 50px;
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #000000;
  }

  .link {
    display: flex;
    justify-content: center;

    .button-base {
      margin-top: 48px;
    }
  }
}
