.filter-select .ant-select-arrow {
  right: 15px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
  padding: 5px 15px;
  border-radius: 10px;
}

.ant-select-selection-item {
  color: #000000;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px;
}

.ant-select-item-option-content {
  color: #000000;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: transparent;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #F8F8F8;
  border-radius: 6px;
}

.ant-select-dropdown {
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #E7E7E7;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border-color: #E7E7E7;
}

