.filter-menu {
  display: flex;
  flex-flow: row wrap;
  align-content: baseline;

  &__option {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #A9A9A9;
    padding: 5px 10px;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;

    &_active {
      color: #000;
      background-color: #F2F2F2;
      border-radius: 10px;
    }
  }

  &__option:not(.filter-menu__option_active):hover {
    color: #000000;
  }
}
