.ContactUsForm {
  margin-bottom: 73px;
}

.ContactUsForm .ant-form-item {
  flex-direction: column;
  margin-bottom: 30px;
}

.ContactUsForm .ant-form-item input,
.ContactUsForm .ant-form-item textarea {
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.38px;
  padding: 12px 20px;
}

.ContactUsForm .ant-form-item input {
  border-color: #CDCDCD;
}

.ContactUsForm .ant-form-item-label {
  display: block;
  align-self: flex-start;
}

.ContactUsForm .ant-form-item-label > label {
  color: #A9A9A9;
  margin-bottom: 10px;
}

.ContactUsForm .ant-btn {
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 11px 40px;
  height: auto;
}

.ContactUsForm .ant-btn-primary {
  background-color: #2C3FEE;
  border-color: #2C3FEE;
}

.ContactUsForm .ant-btn-primary:hover {
  background-color: #5665f1;
  border-color: #5665f1;
}

.ContactUsForm__inner_flex {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
}

.ContactUsForm__inner_flex .ant-form-item {
  margin-bottom: 0;
}

.ContactUsForm__inner_flex .ant-form-item:first-child {
  flex-grow: 2;
  margin-right: 20px;
}

.ContactUsForm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

@media (max-width: 768px) {
  .ContactUsForm__inner_flex {
    display: flex;
    flex-direction: column;
  }

  .ContactUsForm__inner_flex .ant-form-item {
    width: 100%;
  }

  .ContactUsForm__inner_flex .ant-form-item:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
