.AboutUsProsCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 10px;
  padding: 40px 30px;
}

.AboutUsProsCard__img {
  width: 100%;
  margin-bottom: auto;
}

.AboutUsProsCard__img picture {
  width: 100%;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.AboutUsProsCard__img picture img {
  height: 100%;
  display: block;
}

.AboutUsProsCard__description {
  width: 100%;
  margin-top: 30px;
}

.AboutUsProsCard__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #000;
  margin-bottom: 14px;
}

.AboutUsProsCard a {
  color: #7C7C7C;
  position: relative;
  display: inline-block;
}

.AboutUsProsCard a:after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7C7C7C;
  bottom: 2px;
  transition: 0.2s ease-in-out background-color;
}

.AboutUsProsCard a:hover {
  color: #000;
}

.AboutUsProsCard a:hover::after {
  background-color: #000;
}

@media (min-width: 1200px) {
  .AboutUsProsCard {
    flex-direction: row;
    column-gap: 20px;
  }

  .AboutUsProsCard__img {
    width: 40%;
  }

  .AboutUsProsCard__description {
    width: 60%;
    margin-top: 0;
  }
}

@media (min-width: 1400px) {
  .AboutUsProsCard__img {
    width: 50%;
  }

  .AboutUsProsCard__description {
    width: 50%;
  }
}
