.before-footer {
    width: 100%;
}
.container-section-before {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
}


