.tabs .nav-pills {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 10px;
}
.tabs .nav-link {
  padding: 5px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7C7C7C;
  border-radius: 10px;
}
.tabs .nav-link svg path {
  fill: #7C7C7C;
  transition: fill 0.2s ease-in-out;
}
.tabs .nav-link:hover {
  color: #000000;
}
.tabs .nav-link:hover svg path,
.tabs .nav-link.active svg path {
  fill: #000000;
}
.tabs .nav-link.active {
  color: #000000;
  background-color: #F8F8F8;
}
.tabs .nav-link span {
  padding-left: 5px;
}
.tabs .badge {
  margin-left: 8px;
}
/**/