$margin-right: 20px;

@mixin logosRightMargin($logo-numbers, $margin) {
  @each $number in $logo-numbers {
    &__logo:nth-child(#{$number}) {
      margin-right: $margin;
    }
  }
}

.companies {
  padding: 0;

  &_with-borders {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    padding-top: 65px;
    padding-bottom: 80px;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    text-align: center;
  }

  &__title_bold {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
  }

  &__logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 20px auto 0;
    flex-wrap: wrap;
    max-width: 320px;
  }

  &__logo {
    margin-bottom: 5px;
  }

  @include logosRightMargin((1, 3, 5, 7, 8, 10), $margin-right);
  @include logosRightMargin((2, 4, 6, 9, 11, 12), 0px);
}

@media (min-width: 360px) {
  .companies {
    &__logos {
      max-width: 370px;
    }

    @include logosRightMargin((1, 2, 4, 5, 7, 8, 10, 11), $margin-right);
    @include logosRightMargin((3, 6, 9, 12), 0px);
  }
}

@media (min-width: 480px) {
  .companies {
    &__logos {
      max-width: 480px;
    }

    @include logosRightMargin((1, 2, 3, 5, 6, 7, 9, 10, 11), $margin-right);
    @include logosRightMargin((4, 8, 12), 0px);
  }
}

@media (min-width: 576px) {
  .companies {
    &__logos {
      max-width: 540px;
    }

    @include logosRightMargin((1, 2, 3, 5, 6, 7, 8, 10, 11), $margin-right);
    @include logosRightMargin((4, 9, 12), 0px);
  }
}

@media (min-width: 768px) {
  .companies {
    &__logos {
      max-width: 720px;
    }

    @include logosRightMargin((1, 2, 3, 4, 5, 7, 8, 9, 10, 11), $margin-right);
    @include logosRightMargin((6, 12), 0px);
  }
}
