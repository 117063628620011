.wrapper_custom_illustrations {
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    box-shadow: 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset, 0 1px 3px 0 rgba(47, 43, 67, 0.10);
    padding: 28px;
    width: 48%;
    justify-content: space-between;
    min-width: 0;
}

.wrapper_custom_illustrations .first_block {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 60px;
}

.wrapper_custom_illustrations .first_block .link .button-base {
    width: fit-content;
}

.block-title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.64px;
}

.icon {
    width: 144px;
    height: 144px;
    flex-shrink: 0;
}


.btn-light-blue {
    background: #B5F2FA !important;
    color: #1F54C8 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important; /* 150% */
    transition: all 0.2s ease-in-out !important;
}

.btn-light-blue:hover {
    background: #9CEBF5 !important;
}

.btn-light-blue:active {
    background: #90E5F0 !important;
}







@media (max-width: 484px) {
   .wrapper_custom_illustrations {
       display: flex;
       flex-direction: column-reverse;
       align-items: center;
       text-align: center;
   }
    .btn-custom {
        text-align: center;
    }
}



@media (min-width: 1600px) {
    .icon {
        width: 184px;
        height: 184px;
        flex-shrink: 0;
    }
}



@media (max-width: 425px) {
    .block-title {
        font-size: 22px;
        line-height: 30px;
    }
}

@media (max-width: 768px) {
    .wrapper_custom_illustrations {
        width: 100%;
    }
}

@media  (max-width: 1278px) {
    .icon {
        width: 100px;
        height: 100px;
    }
}


@media (max-width: 992px) {
    .wrapper_custom_illustrations {
        width: 100%;
    }
}