.ThankYou {
  h1 {
    margin: 0 0 40px;
  }

  &__firstcol {
    margin-top: 20px;
  }

  &__secondcol {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .InfoField {
    margin-bottom: 20px;
  }

  .EmptyPageFiller {
    margin-top: 80px;
  }

  .InfoFieldWithLink {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .ThankYou {
    &__firstcol {
      margin-top: 163px;
    }

    &__secondcol {
      margin-top: 163px;
    }
  }
}
