.new-search-form {
  display: flex;
  position: relative;
  gap: 12px;
  margin: 48px auto 0;
  max-width: 458px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;


  &_visible {
    opacity: 1;
  }

  &_transparent {
    opacity: 0;
    pointer-events: none;
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__input {
    padding: 12px 32px 12px 16px;
    height: 48px;
    width: 100%;

    color: #2F2B43;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    outline: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    border-radius: 12px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10);
  }

  &__input:hover {
    border: 1px solid var(--alpha-black-20, rgba(47, 43, 67, 0.20));
  }


  &__input:focus {
    background-color: #ffffff;
    border: none;
    box-shadow: 0 0 0 3px rgba(132, 66, 211, 0.48);
  }

  &__input::placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-webkit-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: -webkit-box;

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:-moz-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: flex;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-moz-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: flex;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:-ms-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-ms-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus:-moz-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-moz-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus:-ms-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-ms-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__submit-button-loupe {
    position: absolute;
    top: 14px;
    left: 10px;
    display: none;
    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      display: block;

      path {
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__submit-button-loupe:hover {
    svg {
      path {
        stroke: rgba(145, 48, 222, 1);
      }
    }
  }

  &__reset-button {
    position: absolute;
    top: 18px;
    right: 20px;

    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      display: block;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    &_shown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__reset-button:hover {
    svg {
      transform: rotate(90deg);
    }
  }

  &_fixed {
    position: fixed;
    top: 20px;
    left: 50%;

    min-width: 620px;
    transform: translate(-50%, 0);
    margin-top: 0;
    z-index: 999;

    .search-form__input:not(:focus) {
      background-color: #ffffff;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
      border: 1px solid #ffffff;
    }

    .search-form__input:focus {
      background-color: #ffffff;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
      border: 1px solid #ffffff;
    }
  }

  &_with-text-button {
    .new-search-form__input {
      //padding-left: 25px;
    }

    .new-search-form__submit-button-text {
      display: flex;
      all: unset;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: #B5F2FA;

      box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10);


      color: var(--Info-600, #1F54C8);
      text-align: center;
      font-family: "Euclid Circular A", serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      transition: all;
      transition-duration: 150ms;
      cursor: pointer;
    }

    @media (max-width: 320px) {
      .new-search-form__submit-button-text {
        display: none;
      }

    }    
    
    
    .new-search-form__submit-button-text:hover {
      background: #9CEBF5;
    }


    .new-search-form__submit-button-text:active {
      background: #90E5F0;
    }





    .new-search-form__submit-button-loupe {
      display: none;
    }
  }


}


.search-form {
  margin: 50px auto 0;
  max-width: 612px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: flex;

  &_visible {
    opacity: 1;
  }

  &_transparent {
    opacity: 0;
    pointer-events: none;
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__input {
    padding-right: 55px;
    padding-left: 55px;

    height: 70px;
    width: 100%;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #000000;
    border-radius: 15px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    outline: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    background-color: #F5F5F9;
    box-shadow: none;
    border: 1px solid #F5F5F9;
  }

  &__input:focus {
    background-color: #ffffff;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
  }

  &__input::placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-webkit-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: -webkit-box;

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:-moz-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: flex;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-moz-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: flex;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:-ms-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input::-ms-input-placeholder {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #A9A9A9;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus:-moz-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-moz-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus:-ms-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__input:focus::-ms-input-placeholder {
    color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__submit-button-loupe {
    position: absolute;
    top: 27px;
    left: 25px;

    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      display: block;

      path {
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__submit-button-loupe:hover {
    svg {
      path {
        stroke: rgba(145, 48, 222, 1);
      }
    }
  }

  &__reset-button {
    position: absolute;
    top: 27px;
    right: 25px;

    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      display: block;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    &_shown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__reset-button:hover {
    svg {
      transform: rotate(90deg);
    }
  }

  &_fixed {
    position: fixed;
    top: 20px;
    left: 50%;

    min-width: 620px;
    transform: translate(-50%, 0);
    margin-top: 0;
    z-index: 999;

    .search-form__input:not(:focus) {
      background-color: #ffffff;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
      border: 1px solid #ffffff;
    }

    .search-form__input:focus {
      background-color: #ffffff;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
      border: 1px solid #ffffff;
    }
  }

  &_with-text-button {
    .search-form__input {
      padding-left: 25px;
    }

    .search-form__submit-button-text {
      margin-left: 4px;
    }

    .search-form__submit-button-loupe {
      display: none;
    }
  }
}

.new-search-examples {
  margin-top: 12px;
  color: rgba(47, 43, 67, 0.60);
  text-align: center;
  font-family: "Euclid Circular A", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;

  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &__text {
    margin-bottom: 5px;
    display: inline-flex;

    height: 24px;
    padding: 0 8px;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    border-radius: 20px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    color: var(--alpha-black-60, rgba(47, 43, 67, 0.60));
    text-align: center;

    font-family: "Euclid Circular A", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;

  }

  &__text:focus,
  &__text:active,
  &__text:focus-within,
  &__text:focus-visible,
  &__text:target,
  &__text:hover {
    border-color: #A9A9A9;
    color: rgb(140, 140, 140);
  }

  &__text:first-of-type {
    margin-left: 5px;
  }

  &__text:not(:last-of-type) {
    margin-right: 5px;
  }
}

.search-form_fixed + .search-examples {
  opacity: 0;
}

.search-examples {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #A9A9A9;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &__text {
    font-weight: 500;
    padding: 3px 10px;
    margin-bottom: 5px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #E6E6E6;
    color: #A9A9A9;
  }

  &__text:focus,
  &__text:active,
  &__text:focus-within,
  &__text:focus-visible,
  &__text:target,
  &__text:hover {
    border-color: #A9A9A9;
    color: rgb(140, 140, 140);
  }

  &__text:first-of-type {
    margin-left: 5px;
  }

  &__text:not(:last-of-type) {
    margin-right: 5px;
  }
}

.search-form_fixed + .search-examples {
  opacity: 0;
}

@media (max-width: 991px) {
  .search-form_fixed {
    min-width: 400px;
  }
  .new-search-form_fixed {
    min-width: 400px;
  }
}

@media (max-width: 575px) {
  .search-form {
    margin: 50px 18px 0;


    &_fixed {
      min-width: 400px;
    }

    &__input {
      height: 50px;
      font-size: 18px;
      line-height: 30px;
    }

    &__submit-button-loupe {
      position: absolute;
      top: 17px;
      left: 20px;
    }

    &__reset-button {
      position: absolute;
      top: 18px;
      right: 25px;
    }

    &__input::placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-webkit-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input:-moz-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-moz-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input:-ms-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-ms-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &_with-text-button {
      .search-form__input {
        padding-left: 55px;
      }

      .search-form__submit-button-text {
        display: none;
      }

      .search-form__submit-button-loupe {
        display: block;
      }
    }
  }
  .new-search-form {
    margin: 50px 18px 0;


    &_fixed {
      min-width: 400px;
    }

    &__input {
      height: 50px;
      font-size: 18px;
      line-height: 30px;
    }

    &__submit-button-loupe {
      position: absolute;
      top: 17px;
      left: 20px;
    }

    &__reset-button {
      position: absolute;
      top: 18px;
      right: 25px;
    }

    &__input::placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-webkit-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input:-moz-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-moz-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input:-ms-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &__input::-ms-input-placeholder {
      font-size: 18px;
      line-height: 30px;
    }

    &_with-text-button {
      .search-form__input {
        padding-left: 55px;
      }

      .search-form__submit-button-text {
        display: none;
      }

      .search-form__submit-button-loupe {
        display: block;
      }
    }
  }
}

@media (max-width: 480px) {
  .search-form {
    margin: 50px 0 0;
  }

  .new-search-form {
    margin: 25px 0 0;
  }
}

@media (max-width: 450px) {
  .search-form_fixed {
    min-width: 310px;
  }

  .new-search-form_fixed {
    min-width: 310px;
  }
}

