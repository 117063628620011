.subscription-workflow {
  text-align: center;

  &__text-under-title {
    margin: 13px auto 0;
    max-width: 687px;
  }

  &__list {
    margin: 50px auto 0;
    max-width: 856px;
    display: grid;
    grid-template-columns: 35% 40% 1fr;
  }

  &__list-element {
    padding: 27px 0 0 0;
    display: block;
    text-align: start;
    position: relative;
  }

  &__list-element::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 27px;
    background:
      top 4px left 0px / 14px 14px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
      top 9px left 0px / auto 4px  repeat-x url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/horizontalCompleteSegment.svg");
  }

  &__list-element:first-of-type::before {
      background:
        top 4px left -1px / 14px 14px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
        top 9px left 0px / auto 4px  repeat-x url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/horizontalCompleteSegment.svg");
  }

  &__list-element:last-of-type::before {
    background:
      top 4px left 0px  / 14px   14px   no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
      top 9px left 0px  / 10px   4px    no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/horizontalSmallCompletePart.svg"),
      top 9px right 3px / auto   4px    no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/horizontalUncompleteSegment.svg"),
      top 0px right 0px / 13.5px 22.5px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/arrowToRight.svg");
  }

  &__caption {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #2C3FEE;
  }

  &__small-details {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7C7C7C;
    max-width: 80%;
  }
}

@media (max-width: 767px) {
  .subscription-workflow {
    &__text-under-title {
      padding: 0 20px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 85%;
    }

    &__list-element {
      padding: 0 0 30px 40px;
    }

    &__list-element::before {
      width: 27px;
      height: 100%;
      background:
        top 7px left 4px / 14px 14px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
        top 0px left 9px / 4px auto repeat-y url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/verticalCompleteSegment.svg");
    }

    &__list-element:first-of-type::before {
      background:
        top 6px left 4px / 14px 14px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
        top 7px left 9px / 4px auto no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/verticalCompleteSegment.svg");
    }

    &__list-element:last-of-type::before {
      background:
        top 6px left 4px    / 14px 14px   no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/completePoint.svg"),
        top 0px left 9px    / 4px  10px   no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/verticalSmallCompletePart.svg"),
        bottom 3px left 9px / 4px  auto   no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/verticalUncompleteSegment.svg"),
        bottom 0px left 0px / 22.5px 13.5px no-repeat url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/arrowSteps/arrowDown.svg");
    }

    &__caption {
      max-width: 100%;
    }

    &__small-details {
      max-width: 100%;
    }
  }
}
