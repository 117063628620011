/*browse-by-mood*/
.browse-by-mood .content-row {
    display: flex;
    padding-top: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.browse-by-mood .button-base {
    margin-top: 40px;
}

@media (max-width: 992px) {
    .browse-by-mood .item:nth-child(-n+5) {
        margin-bottom: 30px;
    }
    .browse-by-mood .content-row {
        padding: 0 20px;
        padding-top: 25px;
    }
}

@media (max-width: 767px) {
    .browse-by-mood .item:nth-child(-n+3) {
        margin-bottom: 30px;
    }
}

@media (max-width: 576px) {
    .browse-by-mood {
        overflow: hidden;
    }

    .browse-by-mood .container-custom {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}