.scroll-up {
  position: fixed;
  bottom: 28px;
  right: 28px;

  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: 60px;
  height: 60px;
  z-index: 100;
  display: block;

  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &_show {
    opacity: 0.5;
    visibility: visible;
  }

  &_hide {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    opacity: 0.8;
  }
}
