.ProductHuntBadge {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 8px;
  padding: 0 8px;
  align-items: center;
  height: 32px;
  width: fit-content;
  border-radius: 20px;
  background: #FF560E;

  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}


.ProductHuntBadge:hover {
  background: #FF7539;
  color: #FFF;
}

.ProductHuntBadge:hover .top-badge {
  color: #FF7539;
}

.ProductHuntBadge .top-badge {
  border-radius: 20px;
  background: #FFF;
  padding: 0 8px;
  flex-shrink: 0;
  color: #FF560E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

@media (max-width: 478px) {
  .ProductHuntBadge {
    font-size: 12px;
    line-height: 12px;
    text-wrap: none;
  }
}

@media (max-width: 450px) {
  .ProductHuntBadge {

  }

  .ProductHuntBadge .top-badge {
    font-size: 12px;
    line-height: 18px;
  }
}