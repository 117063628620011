.browse .custom-select-wrapper {
  display: none;
}
.browse .packs-grid__grid-smart {
  margin-top: 0;
}
.browse .browse__divider {
  border-bottom: 1px solid transparent;
  margin-bottom: 50px;
}
.browse .browse__filters .form {
  margin-top: 54px;
  position: relative;
  margin-bottom: 54px;
}
.browse .packs-grid__grid {
  margin-top: 0;
}
.browse .browse__filter-controls {
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  min-height: 42px;
  box-sizing: border-box;
  align-content: center;
}
.browse .browse__filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.browse .collapse-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  padding-left: 30px;
  border-radius: 10px;
}
.browse .collapse-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 10px;
  background-image: url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/icon-search.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.browse .collapse-wrapper .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.browse .collapse-wrapper .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #A9A9A9;
}
.browse .collapse-wrapper .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  padding-left: 20px;

  font-size: 16px;
  color: #000c17;
}
.browse .collapse-wrapper .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  margin-bottom: 20px;
}
.browse .collapse-wrapper .ant-select-auto-complete .ant-select-clear {
  top: 26%;
}
.browse .ant-select-auto-complete {
  width: 85%;
}
.browse .illustrations-pack .illustrations-list {
  padding-top: 0;
}
.browse .browse__catalog .button-base {
  margin-top: 40px;
}
.browse .collapse-wrapper {
  padding: 0 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100vh;
  overflow-y: auto;

  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.browse .collapse-wrapper::-webkit-scrollbar {
  width: 4px;
  display: none;
}
.browse .collapse-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  visibility: hidden;

  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.browse .collapse-wrapper:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.browse .collapse-wrapper .item {
  width: 100%;
}
.browse .collapse-wrapper .collapse-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;

  padding: 0;
  margin: 8px 0;
}
.browse .collapse-wrapper .collapse-btn span {
  display: inline-block;
  margin-left: 5px;
}
.browse .collapse-wrapper .collapse-inner-link {
  height: 32px;

  display: flex;
  font-style: normal;
  font-weight: normal;
  width: max-content;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #7C7C7C;
  padding: 0 8px;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  margin-bottom: 3px;
}
.browse .collapse-wrapper .collapse-inner-link svg path {
  opacity: 0;
}
.browse .collapse-wrapper .collapse-inner-link.more {
  font-size: 14px;
  line-height: 18px;
  color: #A9A9A9 !important;
  text-decoration: underline !important;
}
.browse .collapse-wrapper .collapse-inner-link:hover {
  color: #000000;
}
.browse .collapse-wrapper .collapse-inner-link.active {
  width: max-content;
  align-items: center;
  color: #000000;
  font-weight: 500;
  background: #F2F2F2;
}
.browse .collapse-wrapper .collapse-inner-link.active svg path {
  opacity: 1 !important;
}
.browse .collapse-wrapper .collapse-inner-link.more:hover {
  color: #000 !important;
}
.browse .packs-list {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1510px) {
  .browse .packs-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1270px) {
  .browse .packs-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .browse .ant-select-auto-complete{
    width: 100%;
  }
  .browse .collapse-wrapper .collapse-btn {
    font-size: 19.5px;
  }
}

.browse .container-fluid {
  padding: 0 56px;
}

@media (max-width: 991px) {
  .browse .packs-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .browse .browse__filters {
    padding-bottom: 20px;
  }
  .browse .filter-menu {
    position: static;
    left: 0;
    transform: none;
  }
}

@media (max-width: 767px) {
  .browse .aside-column {
    display: none;
    position: absolute;
    top: 0;
    z-index: 999;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(12px) !important;
    -webkit-backdrop-filter: blur(12px) !important;
  }
  .browse .custom-select-wrapper {
    display: block;

    padding-top: 15px;
    margin-bottom: 50px;

    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(12px) ;
    z-index: 999;
  }
  .browse .collapse-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 20px;
  }
  .browse .ant-select-auto-complete{
    width: 85%;
  }
}

@media (max-width: 575px) {
  .browse .packs-list {
    grid-template-columns: 1fr 1fr;
  }

  .browse .browse__filters .form {
    margin-top: 54px;
  }

  .browse .container-fluid {
    padding: 0 15px;
  }
}

@media screen and (max-width: 569px) {
  .browse .browse__filter-controls {
    min-height: 92px;
    align-content: start;
  }
}

@media (max-width: 400px) {
  .browse .collapse-wrapper .collapse-btn-large {
    min-width: 120px;
  }
  .browse .collapse-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

@media (max-width: 375px) {
  .browse .browse__filters .form {
    margin-top: 54px;
  }
}
