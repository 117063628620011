.ready-to {
    overflow: hidden;
    padding-bottom: 40px;
}

.ready-to .row {
    flex-direction: column;
}

.ready-to .like-title {
    letter-spacing: -4px;
}

.ready-to .description {
    max-width: 610px;
    margin: 0 auto;
    padding-top: 20px;

    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.5px;

    color: #7C7C7C;
}
.ready-to .button-base {
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.ready-to .noice-1 {
    top: 20px;
    left: -60px;
}
.ready-to .noice-2 {
    top: 20px;
    left: -45px;
}
.ready-to .noice-3 {
    bottom: 0;
    left: -60px;
}
.ready-to .noice-4 {
    bottom: -40px;
    left: -40px;
}
.ready-to .noice-5 {
    bottom: -40px;
    right: 20px;
}
.ready-to .noice-7 {
    top: 15px;
    right: -40px;
}
.ready-to .noice-6 {
    bottom: 0;
    right: -60px;
}

@media (max-width: 350px) {
    .ready-to .noice-5 {
        display: none;
    }
}
