.advantage-card {
  background-color: #F1F6F4;
  border-radius: 15px;
  padding: 35px 35px 44px;

  &__illustration {
    width: 100%;
  }

  &__title {
    text-align: center;
    font-family: "PP Editorial New", "Times New Roman", serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.02em;
    margin-top: 5px;
  }

  &__description {
    text-align: center;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #000000;
    opacity: 0.7;
    margin: 16px auto 0;

    &_narrow {
      max-width: 450px;
    }

    &_wide {
      max-width: 570px;
    }
  }
}
