.ResourceCard {
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ResourceCard__title {
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 23px;
}

.ResourceCard__img {
  margin-bottom: 25px;
}

.ResourceCard__img picture {
  width: 100%;
  display: block;
  position: relative;
}

.ResourceCard__img picture img {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
}

.ResourceCard__text {
  margin-bottom: 20px;
  color: #000;
  text-align: center;
}

.ResourceCard__button {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 600;
  color: #000;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #F2F2F2;
  border-radius: 10px;
}

.ResourceCard__button svg {
  margin-right: 10px;
}

.ResourceCard__button:hover {
  color: #808080;
  background-color: #F9F9F9;
}

.ResourceCard__button:hover svg path {
  fill: #808080;
  transition: ease-in-out 0.2s fill;
}
