.how-it-works-second {
    margin-top: 128px;
    display: flex;
    flex-direction: column;
}


body {
    overflow-x: hidden;
}

.how-it-works-second-header {
    color: #000;
    text-align: center;
    font-family: "Euclid Circular A";
    margin: 0 auto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.92px;
    max-width: 638px;
    width: 100%;
}


.cards-container {
    display: flex;
    max-width: 984px;
    width: 100%;
    margin: 64px auto 0;
    flex-direction: column;
    gap: 30px;
}

.cards-row {
    display: flex;
    flex-direction: row;
    width: auto;
    gap: 30px;
}




.s-card {
    position: relative;
    overflow: hidden;
    display: flex;
    max-width: 477px;
    width: 100%;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    box-shadow: 0 1px 3px 0 rgba(47, 43, 67, 0.10), 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset;
    max-height: 532px;
    padding: 28px;
}

.card_text_container {
    margin-top: 364px;
}

.card_text_container .title {
    color: #000;
    font-family: "Euclid Circular A";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.192px;
}

.card_text_container .text {
    margin-top: 8px;
    color: rgba(47, 43, 67, 0.60);
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.product_hunt_card {
}

.product_hunt_card .product_hunt_top_of_the_week {
    position: absolute;
    left: 28px;
    top: 28px;
    max-width: 189px;
    width: 100%;
    height: 58px;
    flex-shrink: 0;
}

.product_hunt_card .product_hunt_card_logo {
    position: absolute;
    top: 35px;
    left: 75px;
    max-width: 402px;
    width: 100%;
    height: 100%;
    max-height: 398px;
    flex-shrink: 0;
}

.tags_graphics_card {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.tags_graphics_card .card_text_container {
    position: absolute;
    bottom: 28px;
}

.white_shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 388px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 51.56%);
}



.tags_graphics_card .card_text_container .text {
    max-width: 291px;
}


.tags_graphics_card .tags_row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
}

.tags_graphics_card .tag {
    height: 52px;
    user-select: none;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 12px 23px;
    border-radius: 30px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    /* Shadows/1 */
    box-shadow: 0 1px 3px 0 rgba(47, 43, 67, 0.10), 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset;
}

.tags_graphics_card .tag span {
    color: #000;
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
}

.tags_graphics_card .tag svg {
    height: 24px;
    width: 24px;
}
.tags_graphics_card .tag-blue {
    background: linear-gradient(0deg, #B5F2FA 0%, #B5F2FA 100%), radial-gradient(108.15% 84.04% at 65.33% 25%, #F9FAB5 0%, #FFDAD1 38.02%, #B5F2FA 100%);
    color: #1F54C8;
}
.tags_graphics_card .tag-blue span {
    color: #1F54C8;
}

.get_once_card {
}

.get_once_card img {
    width: 100%;
    height: 100%;
    max-height: 436px;
}

.get_once_card a {
    all: unset;
}

.get_once_card .assets-link {
    top: 170px;
    left: 107px;
    cursor: pointer;
    position: absolute;
    display: flex;
    padding: 24px 48px;
    align-items: center;
    justify-content: center;
    z-index: 20;
    border-radius: 60px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: radial-gradient(108.15% 84.04% at 65.33% 25%, #FFF 0%, #FFF 38.02%, #B5F2FA 100%);
    box-shadow: 0 1px 3px 0 rgba(47, 43, 67, 0.10), 0 -1px 0 0 rgba(47, 43, 67, 0.10) inset;

    color: #1F54C8;
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
}

.get_once_card .card_text_container {
    position: absolute;
    left: 28px;
    bottom: 28px;
}

.get_once_card .card_text_container .text {
    max-width: 372px;
}


.card_reviews_on_product_hunt {

}

.card_reviews_on_product_hunt .rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.card_reviews_on_product_hunt .rating .stars {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.card_reviews_on_product_hunt .rating .five-point-rating {
    border-radius: 4px;
    background:#D9F9E6;
    padding: 6px 12px;
    display: flex;
    align-items: center;


    color:  #2F9461;
    font-family: "Euclid Circular A";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.216px;
}

.card_reviews_on_product_hunt .card_text_container {
    margin-top: 16px;
}
.card_reviews_on_product_hunt .card_text_container .text {
    max-width: 348px;
}

.card_reviews_on_product_hunt a {
    all: unset;
}
.card_reviews_on_product_hunt .card_button {
    margin-top: 24px;

    color: #000;
    text-align: center;
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */


    width: fit-content;
    transition: all 300ms;
    user-select: none;
    border-radius: 12px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid  rgba(47, 43, 67, 0.10);
    background:  #FFF;
    padding: 8px 12px;
    box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10), 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
}

.card_reviews_on_product_hunt .card_button:hover {
    background: #F6F6F8;
}

.card_reviews_on_product_hunt .card_button:active {
    background: #EDEDF1;
}

.card_reviews_on_product_hunt .biggest_companies {
    margin-top: 130px;
}

.card_reviews_on_product_hunt .biggest_companies span {
    color:  rgba(47, 43, 67, 0.60);
    margin-bottom: 16px;
    display: block;
    font-family: "Euclid Circular A";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}


@media (max-width: 1023px) {
    .cards-container {
        align-items: center;
    }
    .cards-row {
        flex-direction: column;
    }
}

@media (max-width: 540px) {
    .product_hunt_card_logo {
        max-width: 302px !important;
        max-height: 298px !important;
        left: 11px !important;
        top: 36px !important;
    }

    .card_text_container {
        margin-top: 300px !important;
        /*padding-left: 10px;*/
        padding-bottom: 10px;
    }

    .get_once_card .assets-link {
        padding: 14px 28px;
        font-size: 18px;
        top: 50px;
        left: 60px;
    }

    .get_once_card .card_text_container {
        left: 14px;
        bottom: 16px;
        padding-right: 10px;
        padding-left: 5px;
        padding-bottom: 0;
    }

    .card_reviews_on_product_hunt {
        align-items: start !important;
    }

    .card_reviews_on_product_hunt .card_text_container {
        margin: 0 !important;
    }

    .biggest_companies {
        margin-top: 50px !important;
    }

    .logos {
    }

    .logos svg {
        width: 280px;
    }
    .tags_graphics_card .card_text_container {
        padding: 0;
    }

    .card_text_container .title {
        font-size: 20px;
    }

    .tags_graphics_card {
        gap: 2px;
    }
    .tags_row {
        transform: scale(0.68);
    }

    .product_hunt_top_of_the_week {
        max-width: 169px !important;
        height: 38px !important;
        left: 18px !important;
        top: 18px !important;
    }

    .s-card {
        padding: 18px;
        align-items: center;
        width: 100%;
        max-width: 320px;
    }
}

@media (max-width: 344px) {
    .product_hunt_card_logo {
        max-width: 292px !important;
        max-height: 288px !important;
        left: 11px !important;
        top: 36px !important;
    }

    .card_text_container {
        margin-top: 290px !important;
        /*padding-left: 10px;*/
        padding-bottom: 10px;
    }

    .get_once_card .assets-link {
        padding: 14px 28px;
        font-size: 18px;
        top: 50px;
        left: 60px;
    }

    .get_once_card .card_text_container {
        left: 14px;
        bottom: 16px;
        padding-right: 10px;
        padding-bottom: 0;
    }

    .card_reviews_on_product_hunt {
        align-items: start !important;
    }

    .card_reviews_on_product_hunt .card_text_container {
        margin: 0 !important;
    }

    .biggest_companies {
        margin-top: 50px !important;
    }

    .logos svg {
        width: 270px;
    }
    .tags_graphics_card .card_text_container {
        padding-left: 18px;
        padding-right: 10px;
    }

    .card_text_container .title {
        font-size: 20px;
    }

    .tags_graphics_card {
        gap: 2px;
    }
    .tags_row {
        transform: scale(0.68);
    }

    .product_hunt_top_of_the_week {
        max-width: 169px !important;
        height: 38px !important;
        left: 18px !important;
        top: 18px !important;
    }

    .s-card {
        padding: 18px;
        align-items: center;
        width: 100%;
        max-width: 300px;
    }
}












/*.how-it-works-second-section {*/
/*    margin-top: 128px;*/
/*}*/

/*.how-it-works-second-section .title {*/
/*    color: #000;*/
/*    text-align: center;*/
/*    font-family: "Euclid Circular A";*/
/*    font-size: 48px;*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    line-height: 56px; !* 116.667% *!*/
/*    letter-spacing: -1.92px;*/
/*    max-width: 636px;*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*}*/

/*.how-it-works-second-section .blocks {*/
/*    display: flex;*/
/*    padding: 64px 0;*/
/*    justify-content: center;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    gap: 30px;*/
/*    align-self: stretch;*/
/*    !* margin-top: 64px; *!*/
/*}*/

/*.overlap-group {*/
/*    left: 28px;*/
/*    position: relative;*/
/*    top: 28px;*/
/*}*/


/*.explore-button-in-card {*/
/*    all: unset;*/
/*    margin: 0 auto 0;*/
/*    width: max-content;*/
/*    text-align: center;*/
/*    display: flex !important;*/
/*    flex-flow: row nowrap;*/
/*    justify-content: center !important;*/
/*    justify-items: center;*/
/*    border-radius: 12px;*/
/*    border: 1px solid rgba(47, 43, 67, 0.1);*/
/*    background: #fff;*/
/*    -webkit-transition: all 0.15s linear;*/
/*    -o-transition: all 0.15s linear;*/
/*    transition: all 0.15s linear;*/
/*    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.1) inset,*/
/*    0px 1px 3px 0px rgba(47, 43, 67, 0.1);*/
/*}*/

/*.explore-button-in-card span {*/
/*    padding: 8px 16px;*/
/*    display: block;*/
/*}*/

/*.explore-button-in-card:hover {*/
/*    border: 1px solid rgba(47, 43, 67, 0.20);*/
/*    background: #F6F6F8;*/
/*    color: #000;*/
/*    box-shadow: 0px -1px 0px 0px rgba(47, 43, 67, 0.10) inset, 0px 1px 3px 0px rgba(47, 43, 67, 0.10);*/
/*    z-index: 90;*/
/*    background-clip: content-box;*/
/*}*/

/*.explore-button-in-card:active {*/
/*    color: #000;*/
/*    border: 1px solid rgba(47, 43, 67, 0.20);*/
/*    background: #EDEDF1;*/
/*}*/


/*@media (max-width: 1020px) {*/
/*    .how-it-works-second-section {*/
/*        margin-top: 128px;*/
/*    }*/

/*    .how-it-works-second-section .card-row {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*    }*/
/*}*/

/*.how-it-works-second-section .card-row {*/
/*    display: flex;*/
/*    gap: 30px;*/
/*}*/
/*!*TODO: REFACTOR*!*/
/*!*@media (max-width: 530px) {*!*/
/*!*    .how-it-works-second-section {*!*/
/*!*        transform: scale(0.8);*!*/
/*!*        margin-top: -230px !important;*!*/
/*!*        margin-bottom: -300px !important;*!*/
/*!*    }*!*/

/*!*    .how-it-works-second-section .title {*!*/
/*!*        font-size: 38px !important;*!*/
/*!*    }*!*/
/*!*}*!*/

/*!*@media (max-width: 435px) {*!*/
/*!*    .how-it-works-second-section {*!*/
/*!*        transform: scale(0.7);*!*/
/*!*    }*!*/
/*!*}*!*/

/*!*@media (max-width: 385px) {*!*/
/*!*    .how-it-works-second-section {*!*/
/*!*        transform: scale(0.6);*!*/
/*!*    }*!*/
/*!*}*!*/
