:root {
  --success-500: rgba(83, 180, 131, 1);
  --success-700: rgba(47, 118, 87, 1);
  --action: rgba(145, 48, 222, 1);
  --alpha-black10: rgba(47, 43, 67, 0.1);
  --basewhite: rgba(255, 255, 255, 1);
  --bg: rgba(242, 242, 242, 1);
  --black: rgba(0, 0, 0, 1);
  --blue: rgba(44, 63, 238, 1);
  --body-2-500-medium-font-family: "Inter", Helvetica;
  --body-2-500-medium-font-size: 16px;
  --body-2-500-medium-font-style: normal;
  --body-2-500-medium-font-weight: 500;
  --body-2-500-medium-letter-spacing: -0.16px;
  --body-2-500-medium-line-height: 24px;
  --button-bg: rgba(231, 231, 231, 1);
  --caption-1-500-medium-font-family: "Inter", Helvetica;
  --caption-1-500-medium-font-size: 14px;
  --caption-1-500-medium-font-style: normal;
  --caption-1-500-medium-font-weight: 500;
  --caption-1-500-medium-letter-spacing: -0.14px;
  --caption-1-500-medium-line-height: 20px;
  --card: 0px 20px 50px 0px rgba(0, 0, 0, 0.07);
  --description-font-family: "Euclid Circular A", Helvetica;
  --description-font-size: 14px;
  --description-font-style: normal;
  --description-font-weight: 500;
  --description-letter-spacing: 0px;
  --description-line-height: 18px;
  --green: rgba(89, 234, 158, 1);
  --illustration: 0px 100px 100px 0px rgba(0, 0, 0, 0.2);
  --info-600: rgba(31, 84, 200, 1);
  --input: rgba(230, 230, 230, 1);
  --input-text: rgba(169, 169, 169, 1);
  --light-blue: rgba(181, 242, 250, 1);
  --popup-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  --primary-900: rgba(47, 43, 67, 1);
  --PRM: 0px 20px 40px 0px rgba(0, 0, 0, 0.16);
  --red: rgba(254, 52, 52, 1);
  --shadows-1: 0px 1px 3px 0px rgba(47, 43, 67, 0.1);
  --test: 0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --text: rgba(124, 124, 124, 1);
  --text-16-font-family: "Euclid Circular A", Helvetica;
  --text-16-font-size: 16px;
  --text-16-font-style: normal;
  --text-16-font-weight: 400;
  --text-16-letter-spacing: 0px;
  --text-16-line-height: 24px;
  --text-18-font-family: "Euclid Circular A", Helvetica;
  --text-18-font-size: 18px;
  --text-18-font-style: normal;
  --text-18-font-weight: 400;
  --text-18-letter-spacing: 0px;
  --text-18-line-height: 26px;
  --text-20-font-family: "Euclid Circular A", Helvetica;
  --text-20-font-size: 20px;
  --text-20-font-style: normal;
  --text-20-font-weight: 400;
  --text-20-letter-spacing: -0.5px;
  --text-20-line-height: 32px;
  --title-28-font-family: "Euclid Circular A", Helvetica;
  --title-28-font-size: 28px;
  --title-28-font-style: normal;
  --title-28-font-weight: 400;
  --title-28-letter-spacing: -0.5px;
  --title-28-line-height: 36px;
  --title-42-font-family: "Euclid Circular A", Helvetica;
  --title-42-font-size: 42px;
  --title-42-font-style: normal;
  --title-42-font-weight: 400;
  --title-42-letter-spacing: -2px;
  --title-42-line-height: 48px;
  --title-62-font-family: "Euclid Circular A", Helvetica;
  --title-62-font-size: 62px;
  --title-62-font-style: normal;
  --title-62-font-weight: 400;
  --title-62-letter-spacing: -3px;
  --title-62-line-height: 70px;
  --title-72-font-family: "Euclid Circular A", Helvetica;
  --title-72-font-size: 72px;
  --title-72-font-style: normal;
  --title-72-font-weight: 400;
  --title-72-letter-spacing: -3px;
  --title-72-line-height: 72px;
  --white: rgba(255, 255, 255, 1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("fonts/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("fonts/EuclidCircularA-Medium-WebXL.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("fonts/EuclidCircularA-Semibold-WebXL.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "DM Sans";
  src: url("fonts/dmsans-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #7c7c7c;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
::selection {
  background: #ff2067;
  color: #fff;
}
img {
  pointer-events: none;
  vertical-align: middle;
  border-style: none;
}
a,
button {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: 0 !important;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none !important;
}
a[href] {
  cursor: pointer;
}
strong {
  font-weight: normal;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
.like-title,
.title_big {
  margin: 0;
  padding: 0;

  font-family: "PP Editorial New", "Times New Roman", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 115%;
  letter-spacing: -0.04em;
  color: #000000;
}

h2 {
  margin: 0;
  padding: 0;

  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: -3px;
  color: #000000;
}
.title_medium {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: -3px;
  color: #000000;
  font-weight: 600;
}
h3 {
  margin: 0;
  padding: 0;

  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -2px;
  color: #000000;
}
.title_small {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -2px;
  color: #000000;
}
h4 {
  margin: 0;
  padding: 0;

  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: #000000;
}
h5 {
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #000000;
}
mark {
  background-color: transparent;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
figure {
  margin: 0;
}
.small-text {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #7c7c7c;
}

/*layout*/
.container-custom {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .container-custom {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container-custom {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container-custom {
    max-width: 960px !important;
  }
}

@media only screen and (min-width: 1270px) {
  .container-custom {
    max-width: 1200px !important;
  }
}

@media only screen and (min-width: 1510px) {
  .container-custom {
    max-width: 1490px !important;
  }
}

.section {
  margin-top: 80px;
}
.section.bordered {
  padding-top: 65px;
  border-top: 1px solid #f2f2f2;
}
.opacity {
  opacity: 0;
  visibility: hidden;
}
.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.rotate {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.hidden {
  display: block;

  opacity: 0;
  visibility: hidden;
  max-height: 0 !important;
  animation-duration: 0.2s;
  transition: all 0.2s;
}
.show {
  opacity: 1;
  visibility: visible;
  animation-duration: 0.2s;
  transition: all 0.2s;
}
.collapse-element {
  position: relative;
  left: -5px;
  animation-duration: 0.2s;
  transition: all 0.2s;
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@media screen and (max-width: 1510px) {
  h1,
  .like-title {
    font-size: 90px;
    color: #000000;
  }
  .title_big {
    font-size: 90px;
  }
  .title_medium {
    font-size: 60px;
  }
  h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .title_small {
    font-size: 40px;
    line-height: 50px;
  }
  h4 {
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (max-width: 1269px) {
  h1,
  .like-title {
    font-size: 70px;
  }
  .title_big {
    font-size: 65px;
    line-height: 60px;
    letter-spacing: -3px;
  }
  .title_medium {
    font-size: 50px;
    line-height: 58px;
    letter-spacing: -2px;
  }
  h3 {
    font-size: 34px;
    line-height: 45px;
  }
  .title_small {
    font-size: 34px;
    line-height: 45px;
  }
  .section {
    margin-top: 70px;
  }
}
@media (max-width: 991px) {
  h3 {
    font-size: 35px;
  }
  h4 {
    font-size: 25px;
    line-height: 129%;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -2px;
  }
  h3 {
    font-size: 32px;
    line-height: 45px;
  }
  h4 {
    font-size: 22px;
    line-height: 129%;
  }
}

@media (max-width: 575px) {
  body {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 400px) {
  h1,
  .like-title {
    font-size: 70px;
  }
  .title_big {
    font-size: 42px;
    line-height: 55px;
    letter-spacing: normal;
  }
  .title_medium {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: normal;
  }
  .title_small {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: normal;
  }
}

@media (max-width: 350px) {
  h1,
  .like-title {
    font-size: 60px;
  }
}

/*ant-design customization*/

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px;
  margin-right: 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.ant-notification {
  left: 0;
  right: 0;
  margin-right: 0;
}
.ant-notification-notice {
  width: 480px;

  padding: 10px 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.ant-notification-notice-close {
  right: 12px;
  top: 11px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  display: none;
}
.ant-popover .ant-popover-inner-content {
  min-width: 180px;
  max-width: 200px;
  padding: 28px;
}

@media (max-width: 450px) {
  .ant-popover .ant-popover-inner-content {
    width: 240px;
  }
}

.ant-popover .ant-popover-inner {
  border-radius: 10px;
}
.ant-popover .ant-popover-arrow {
  display: none;
}
.ant-popover {
  z-index: 1500;
}

/*tooltip*/
.ant-tooltip-inner {
  padding: 10px 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  border-radius: 10px;
}
.ant-tooltip-arrow-content {
  display: none;
}
/**/
.sticky-element {
  position: sticky;
  top: 0;
}
.top-50 {
  top: 30px;
}

div.ant-form-item-explain.ant-form-item-explain-error {
  display: none;
}

input {
  font-size: 16px !important;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}
