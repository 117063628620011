.button-base {
    border: none;
    webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-shadow: none;
    box-shadow: none;
}

.button-base.large {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 60px;
    padding: 0 16px;

    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #FFFFFF !important;
    background: #2C3FEE;
    border-radius: 10px;
}
.button-base.large:hover,
.button-base.large:focus,
.button-base.large:active {
    background-color: rgba(44, 63, 238, 0.8);
}
.button-base.large.purple {
    background-color: rgba(145, 48, 222, 1);
}
.button-base.large.purple:hover,
.button-base.large.purple:focus,
.button-base.large.purple:active {
    background-color: rgba(145, 48, 222, 0.8);
}
.button-base.big {
    height: 50px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 20px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF !important;
    border-radius: 10px;
    border: 2px solid transparent;

}

.button-base.big.outline {
    background-color: transparent;
    min-width: auto;
}
.button-base.big.danger {
    background-color: #FE3434;
    color: #ffffff;
}
.button-base.big.danger:hover {
    background-color: #FE6767;
}
.button-base.big.purple {
    background-color: rgba(145, 48, 222, 1);
}
.button-base.big.purple:hover {
    background-color: rgba(145, 48, 222, 0.8);
}
.button-base.big.black {
    background-color: rgba(0, 0, 0, 1);
    color: #ffffff;
}
.button-base.big.black:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
.button-base.big.white {
    background-color: rgba(255, 255, 255, 1);
    color: #000000 !important;
}
.button-base.big.white:hover {
    background-color: #E5E5E5;
}
.button-base.big.purple:hover {
    background-color: rgba(145, 48, 222, 0.8);
}
.button-base.big.green {
    background-color: rgba(37, 234, 92, 1);
    color: #000000 !important;
}
.button-base.big.green:hover {
    background-color: rgba(37, 234, 92, 0.8);
}

.button-base.big.green:disabled,
.button-base.big.green.disabled{
     color: #ffffff !important;
     background-color: #a9a9a9;
     border-color: #a9a9a9;
     cursor: not-allowed;
 }
.button-base.big.blue {
    background-color: rgba(44, 63, 238, 1);
    color: #ffffff!important;
}
.button-base.big.blue:hover {
    background-color: rgba(44, 63, 238, 0.8);
}
.button-base.big.outline.red {
    background-color: transparent;
    border-color: rgba(254, 52, 52, 1);
    color: rgba(254, 52, 52, 1) !important;
}
.button-base.big.outline.red:hover {
    border-color: rgba(254, 52, 52, 0.7);
    color: rgba(254, 52, 52, 0.7) !important;
}
.button-base.big.outline.black {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1) !important;
}
.button-base.big.outline.black:hover {
    border-color: rgba(0, 0, 0, 0.6);
    color: rgba(0, 0, 0, 1) !important;
}
.button-base.tiny {

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    justify-content: center;

    padding: 0 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    background: #000000;
    border-radius: 10px;
}
.button-base.tiny:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
.button-base.tiny.disabled {
    background-color: #E7E7E7;
    color: #A9A9A9 !important;
}

.button-base.custom {
    padding: 10px 15px;
    min-width: 168px;
    max-width: max-content;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    border: 1px solid #000000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: transparent;
}
.button-base.custom:hover {
    background-color: #000000;
    color: #ffffff;
}

.button-base.custom.icon {
    grid-gap: 9px;
    align-items: center;
}
.button-base.custom.icon span:first-of-type {
    text-align: end;
}
.button-base.custom.icon span:last-of-type {
    text-align: start;
}
.button-base.custom.icon svg path {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.button-base.custom.icon:hover svg path {
    fill: #FFFFFF;
}

@media (max-width: 400px) {
    .button-base.large {
        font-size: 20px;
    }
}

@media (max-width: 375px) {
    .button-base:not('.big').outline {
        width: 100%;
    }
}
