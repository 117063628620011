.tag-cloud {
  &__title {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #000000;
    text-align: center;
  }

  &__caption {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #7C7C7C;
    max-width: 650px;
    text-align: center;
    margin: 13px auto 0;
  }

  &__container {
    max-width: 925px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 25px auto 0;
    box-sizing: border-box;
  }

  &__item {
    display: block;
    margin: 5px;
  }

  &__tag {
    display: block;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #000000;
    white-space: nowrap;

    background-color: #F1F6F4;
    border-radius: 15px;
    padding: 10px 15px;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__tag:hover {
    background-color: lighten(#F1F6F4, 2);
    color: lighten(#000000, 40);
  }

  &__tag:active,
  &__tag:focus,
  &__tag:focus-visible,
  &__tag:focus-within {
    background-color: darken(#F1F6F4, 5);
    color: #000000;
  }
}
